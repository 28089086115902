.logos-carousel {
    margin: $grid-row-gap 0;

    &__box {
        position: relative;
    }
    &__slider{
        margin: 0 (-$grid-gutter-em);
        @include breakpoint(mw) {
            padding: 0 4rem;
        }
        .slick-track {
            display: flex ;
            align-items: center;
        }
    }
    &__item {
        img {
            max-height: 80px;
            margin: auto
        }
    }
}
.logos-carousel-btn {
    @extend %slider-btn;
    position: absolute;
    top: 0;
    bottom: 0;

    @include breakpoint(mw) {
        background-color: transparent;
        width: auto;
        color: $color-grey-dark-2;
    }

    svg {
        @include breakpoint(mw) {
            width: 20px;
            height: 30px;
        }

    }

    &--mobile {
        @extend %slider-btn--mobile;
    }

    &--prev {
        @extend %slider-btn--prev;
        left: 0;
        @include breakpoint(mw) {
            left: 2rem
        }
    }
    &--next {
        @extend %slider-btn--next;
        right: 0;
        @include breakpoint(mw) {
            right: 2rem;
        }
    }
}