/* ================================================================
   Table Theme Styles
   ================================================================ */

table {
	margin:1em 0;
}

.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 1rem;
	background-color: transparent;
}

.table th,
.table td {
	padding: 0.75rem;
	vertical-align: top;
	border:0;
	border-top: 1px solid #dee2e6;
}

.table thead th {
	vertical-align: bottom;
	border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
	border-top: 2px solid #dee2e6;
}

  .table .table {
	background-color: #fff;
  }

  .table-sm th,
  .table-sm td {
	padding: 0.3rem;
  }

  .table-bordered {
	border: 1px solid #dee2e6;
  }

  .table-bordered th,
  .table-bordered td {
	border: 1px solid #dee2e6;
  }

  .table-bordered thead th,
  .table-bordered thead td {
	border-bottom-width: 2px;
  }

  .table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(0, 0, 0, 0.05);
  }

  .table-hover tbody tr:hover {
	background-color: rgba(0, 0, 0, 0.075);
  }

  .table-primary,
  .table-primary > th,
  .table-primary > td {
	background-color: #b8daff;
  }

  .table-hover .table-primary:hover {
	background-color: #9fcdff;
  }

  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
	background-color: #9fcdff;
  }

  .table-secondary,
  .table-secondary > th,
  .table-secondary > td {
	background-color: #d6d8db;
  }

  .table-hover .table-secondary:hover {
	background-color: #c8cbcf;
  }

  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
	background-color: #c8cbcf;
  }

  .table-success,
  .table-success > th,
  .table-success > td {
	background-color: #c3e6cb;
  }

  .table-hover .table-success:hover {
	background-color: #b1dfbb;
  }

  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
	background-color: #b1dfbb;
  }

  .table-info,
  .table-info > th,
  .table-info > td {
	background-color: #bee5eb;
  }

  .table-hover .table-info:hover {
	background-color: #abdde5;
  }

  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
	background-color: #abdde5;
  }

  .table-warning,
  .table-warning > th,
  .table-warning > td {
	background-color: #ffeeba;
  }

  .table-hover .table-warning:hover {
	background-color: #ffe8a1;
  }

  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
	background-color: #ffe8a1;
  }

  .table-danger,
  .table-danger > th,
  .table-danger > td {
	background-color: #f5c6cb;
  }

  .table-hover .table-danger:hover {
	background-color: #f1b0b7;
  }

  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
	background-color: #f1b0b7;
  }

  .table-light,
  .table-light > th,
  .table-light > td {
	background-color: #fdfdfe;
  }

  .table-hover .table-light:hover {
	background-color: #ececf6;
  }

  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
	background-color: #ececf6;
  }

  .table-dark,
  .table-dark > th,
  .table-dark > td {
	background-color: #c6c8ca;
  }

  .table-hover .table-dark:hover {
	background-color: #b9bbbe;
  }

  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
	background-color: #b9bbbe;
  }

  .table-active,
  .table-active > th,
  .table-active > td {
	background-color: rgba(0, 0, 0, 0.075);
  }

  .table-hover .table-active:hover {
	background-color: rgba(0, 0, 0, 0.075);
  }

  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
	background-color: rgba(0, 0, 0, 0.075);
  }

  .table .thead-dark th {
	color: #fff;
	background-color: #212529;
	border-color: #32383e;
  }

  .table .thead-light th {
	color: #495057;
	background-color: #e9ecef;
	border-color: #dee2e6;
  }

  .table-dark {
	color: #fff;
	background-color: #212529;
  }

  .table-dark th,
  .table-dark td,
  .table-dark thead th {
	border-color: #32383e;
  }

  .table-dark.table-bordered {
	border: 0;
  }

  .table-dark.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(255, 255, 255, 0.05);
  }

  .table-dark.table-hover tbody tr:hover {
	background-color: rgba(255, 255, 255, 0.075);
  }

  @media (max-width: 575.98px) {
	.table-responsive-sm {
	  display: block;
	  width: 100%;
	  overflow-x: auto;
	  -webkit-overflow-scrolling: touch;
	  -ms-overflow-style: -ms-autohiding-scrollbar;
	}
	.table-responsive-sm > .table-bordered {
	  border: 0;
	}
  }

  @media (max-width: 767.98px) {
	.table-responsive-md {
	  display: block;
	  width: 100%;
	  overflow-x: auto;
	  -webkit-overflow-scrolling: touch;
	  -ms-overflow-style: -ms-autohiding-scrollbar;
	}
	.table-responsive-md > .table-bordered {
	  border: 0;
	}
  }

  @media (max-width: 991.98px) {
	.table-responsive-lg {
	  display: block;
	  width: 100%;
	  overflow-x: auto;
	  -webkit-overflow-scrolling: touch;
	  -ms-overflow-style: -ms-autohiding-scrollbar;
	}
	.table-responsive-lg > .table-bordered {
	  border: 0;
	}
  }

  @media (max-width: 1199.98px) {
	.table-responsive-xl {
	  display: block;
	  width: 100%;
	  overflow-x: auto;
	  -webkit-overflow-scrolling: touch;
	  -ms-overflow-style: -ms-autohiding-scrollbar;
	}
	.table-responsive-xl > .table-bordered {
	  border: 0;
	}
  }

  .table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive > .table-bordered {
	border: 0;
  }

  // Content Table
  .table-content {
    width: calc(100% + #{$grid-gutter}*2);
    margin: 0 (-$grid-gutter);
    border-spacing: 0;
    border-collapse: collapse;
    font-size: 14px;

    @include breakpoint(mw) {
      width: 100%;
      margin: 0;
    }

    thead {
      display: none;
      @include breakpoint(mw) {
        display: table-header-group;
      }
      tr {
        background-color: $color-grey-light-3;
        border-bottom: 1px solid $color-grey-light-2;
        font-size: 16px;
      }
      td {
        color: $color-primary;
        font-weight: $fontWeight-bold;
      }
    }

    tr:nth-child(even) {background-color: $color-grey-light-3;}

    tr {
      display: flex;
      flex-direction: column;
      padding: 8px 0;
      @include breakpoint(mw) {
        flex-direction: row;
        justify-content: space-around;
      }
    }
    td {
      flex: 1;
      padding: 5px $grid-gutter;
      &:not(:last-child) {
          margin-right: $grid-gutter;
      }
      &::before {
        content: attr(data-label);
        margin-right: 5px;
        font-weight: bold;
        color: $color-primary;
        text-transform: uppercase;
        @include breakpoint(mw) {
          content: none;
        }
      }
    }
    a {
      color: $color-secondary;
      text-decoration: underline;
    }

  }

.table-content td:nth-child(2) {
  @include breakpoint(mw) {
    min-width: 240px;
  }
}
.class-filters {
  @include breakpoint(mw) {
    display: flex;
    align-items: center;
    margin-bottom: 15px;    
  }
}
.filter-wrap {
    position: relative;
    margin-bottom: 8px;
    @include breakpoint(mw) {
      margin-right: 8px;
    }

    &::after {
      content: '';
      border: solid $color-secondary;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      position: absolute;
      bottom: 20px;
      right: 15px;

      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
}
.filter-wrap label {
  position: absolute;
  top: 10px;
  left: 16px;
  pointer-events: none;
}
.filter-wrap select {
  appearance: none;
  padding: 23px 25px 0 15px;
  height: 70px;
  cursor: pointer;
  font-family: $font-primary;
  font-weight: $fontWeight-bold;
  color: $color-grey-dark-1;
  width: 100%;
}
.filter-wrap--mobile {
  @include breakpoint(mw) {
    display: none;
  }
}
.is-full {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top:22px;
    width: 100%;
    border: 1px solid $color-secondary;
    opacity: .8;
  }
}
.is-full__td {
  color: $color-secondary;
}