.interior-top {
}

.interior-content,
.interior-rail {
}

.page-extra-container {
    display: none;
}

.page-extra-col {
    text-align: center;
    @include x-rem(font-size, 11px);
}

.interior-main p a {  
    color: $color-secondary;
    text-decoration: underline;
    &:hover, &:active, &:focus {
        text-decoration: none;
    }
}

@media (min-width: $bp-mw) {
    .interior-content,
    .interior-rail {
    }

    .page-extra-container {
        display: block;
    }

    .page-extra-col {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        @include x-rem(font-size, 16px);
    }
}
.interior-content-grid {
    display: flex;
    flex-direction: column;
    
    @include breakpoint(mw) {
        flex-direction: row;
        flex-wrap: wrap;
    }

    h1, h3 {
        margin-bottom: $grid-gutter;
    }
}
.interior-rail-left {
    @include breakpoint(mw) {
        width: calc(75% - 40px);
        margin-right: 40px;
    }
}
.interior-rail-center {
    @include breakpoint(mw) {
        width: 40%;
    }
}
.interior-rail-right {
    @include breakpoint(mw) {
        width: 25%;
    }
}
.interior-left {
    margin-bottom: $grid-gutter;
    @include breakpoint(mw) {
        width: calc(50% - 80px);
        margin: 0 40px 40px;
    }
}
.interior-right {
    @include breakpoint(mw) {
        width: 50%;
    }
}
.interior-rail-full {
    @include breakpoint(mw) {
        width: 100%;
    }
}
.interior-content {
}
.interior-content-narrow {
    margin-top: $grid-row-gap;
    @include breakpoint(mw) {
        max-width: $bp-mw;
        margin-left: auto;
        margin-right: auto;
    }

}
.interior-content-inner {
    margin: $grid-row-gap 0;
}