.page-banner {
    position: relative;
    background-color: $color-grey-light-3;
    padding: 40px $grid-gutter 30px;
    margin: 0 (-$grid-gutter);
    line-height: 1.8;
    @include breakpoint(mw) {
        margin: 0;
        padding: 15px 50px;
    }
    &-inner {
        display: flex;
        flex-direction: column;
    }

    &::after {
        @extend %triangle-top-right;
    }
    ul {
        list-style: none; 

        li {
            &::before {
                content: "\2022";  
                color: $color-secondary; 
                font-weight: bold; 
                display: inline-block; 
                width: 1em; 
                margin-left: -1em;
            }
        }
    }
    p {
        margin-bottom: 20px;

        a {
            color: $color-secondary;
        }
    }
    .btn--active {
        margin: 0;
        font-weight: $fontWeight-semibold;
        color: $color-invert;
        @include breakpoint(mw) {
            margin: auto;
        }
    }
}