/* ================================================================
   Simple Banner
   ================================================================ */

.banner {

}
.banner__image {
    
}
.banner__title {
    
}
.banner__description {
    
}