.post {
    margin: 0 0;
    &__featured-image {
        margin: 0 (-$grid-gutter);
        @include breakpoint(mw) {
            margin: 0;
        }
    }
    &__share {
        margin: $grid-gutter 0;
        ul {
            @include resetList;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            grid-gap: $grid-gutter;
        }
        li {

        }
        a {
            display: flex;
        }
        svg {
            fill: $color-grey-dark-2;
            width: 30px;
            height: 30px;
        }
    }
    &__heading {
        font-size: 23px;
        line-height: 1.2;
        margin-bottom: 5px;
        @include breakpoint(mw) {
            font-size: 40px;
        }
    }
    &__meta {
        color: $color-secondary;
        font-weight: $fontWeight-bold;
        text-transform: uppercase;
    }
    &__content {
        margin: 30px 0;
    }
    a {
        color: $color-secondary;
    }
}
.related-posts {
    border: 2px solid $color-grey-light-1;
    margin: $grid-gutter 0 0;
    padding: $grid-gutter;
    @include breakpoint(mw) {
        margin: 0 0 $grid-gutter;
    }
    ul {
        @include resetList;

        li {
            margin-bottom: $grid-gutter/2;
            position: relative;
            padding-left: $grid-gutter;
            font-size: 14px;
            &::before {
                content: '';
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: $color-secondary;
                position: absolute;
                top: 8px;
                left: 0;
            }
        }
    }
}