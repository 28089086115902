.promo__container {
    position: relative;
    margin: 0 (-$grid-gutter) 0 0;
    @include breakpoint(mw) {
        margin: 0;
    }
    .btn--link {
        margin-left: auto;
        margin-right: 1rem;
    }
}
.promo__slider {
    @include resetList;
    position: relative;
}
.promo__item {
    color: #fff;

    @include breakpoint(mw) {
        display: flex !important;
        flex-wrap: wrap;
    }
    &:hover {
        text-decoration: none;
    }
    &:not(:last-child) {
        margin-right: 1rem;
        @include breakpoint(mw) {
            margin-right: 0;
        }
    }
}
.promo__image-box {
    @include breakpoint(mw) {
        width: 45%;
    }
}
.promo__image {
    margin: 0;
    position: relative;
    background-size: cover;
    width: 100%;

    .aspect-ratio {
        padding-top: 66.66%; /* 3:2 Aspect Ratio (divide 2 by 3 = 0.6666)  */
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        @include breakpoint(mw) {
            position: relative;
        }
    }
}

.promo__content {
    background-color: $color-grey-light-3;
    color: $color-primary;
    font-weight: $fontWeight-bold;
    padding: 1rem 1rem 2rem;
    z-index: 3;

    @include breakpoint(mw) {
        position: relative;
        padding: 4rem 2rem;
        font-size: 23px;
        width: 55%;
    }

    .heading-line {
        display: none;
        @include breakpoint(mw) {
            display: block;
        }
    }
}

.promo__name {
    @include breakpoint(mw) {
        &:hover {
            text-decoration: underline;
        }
    }
}

.promo__slider-controls {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    @include breakpoint(mw) {
        margin: 0 6rem;
    }
}
.promo__bottom {
    @include breakpoint(mw) {
        display: flex;
        position: absolute;
        bottom: 2rem;
        right: 2rem;
    }
}

.promo__slider-btn {
    @extend %slider-btn;
    color: $color-grey-dark-2;

    @include breakpoint(mw) {
    }
    &--prev {
        @extend %slider-btn--prev;
        display: none;
        @include breakpoint(mw) {
            display: inherit;
            order: -2;
        }
    }
    &--next {
        @extend %slider-btn--next;
        display: none;
        @include breakpoint(mw) {
            display: inherit;
        }
    }
    &--pause {
        @extend %slider-btn--pause;
        margin-left: 1rem;
    }
    &--dots {
        @extend %slider-btn--dots;
        order: -1;
    }
}
