.breadcrumbs {
    @include resetList;
    margin-top: $grid-gutter;
    margin-bottom: $grid-gutter;
}

.breadcrumbs__item {
    position:relative;
    display: inline;
    padding-right: 30px;

    &::after {
        @extend %arrow, %arrow--right;
        color: $color-grey-dark-2;
        border-width: 0 2px 2px 0;
        position: absolute;
        right: 16px;
        top: 6px;
    }

    &:last-child {
        padding: 0;

        &::after { content: none; }
    }
}

.breadcrumbs__link {
    display: inline-block;
    font-weight: $fontWeight-semibold;
    color: $color-secondary;
}

.breadcrumbs__active {
    color: $color-grey-dark-2;
}

@media (min-width: $bp-mw) {
    .breadcrumbs {
        display: block;
    }
}