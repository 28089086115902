footer {
     margin-top: $grid-row-gap;
}
.footer__top {
     background: $color-grey-light-3;
     border-top: 5px solid $color-secondary;

     &-inner {
          display: flex;
          flex-direction: column;
          @include x-rem(padding, 60px $grid-gutter );
          
          @include breakpoint(mw) {
               flex-direction: row;
               margin: auto;
               @include x-rem(padding, 60px 0);
          }
     }
}
.footer__nav {
     @include resetList;
     display: flex;
     flex-direction: column;
     @include x-rem(margin-top, 60px);
     
     @include breakpoint(mw) {
          margin-top: 0;
          flex-direction: row;
          width: 55%;
          padding: 0 40px;
     }
}
.footer__nav-box {
     display: flex;
     flex-direction: column;
     padding: 0;
     @include breakpoint(mw) {
          width: 33.33%;
     }
     @include ie() {
          display: inline-block;
     }
     ul {
          @include resetList;
          &[data-expander-target] {
               @include breakpoint(mw) {
               display: block;
               }
          }
     }
     &:last-of-type {
          @include breakpoint(mw) {
               width: 66.66%;
          }
          ul {
               column-count: 2;
          }
     } 
     &:not(:last-of-type) {
          @include breakpoint(mw) {
               margin-right: 1rem;
          }
     }
}
.footer__nav-heading {
     font-size: 1rem;
     margin-bottom: 1rem;
     color: $color-secondary;
     text-transform: uppercase;
     font-weight: $fontWeight-bold;
     transition: all .2s;
     position: relative;
     min-width: max-content;

     &:hover, &:focus {
          text-decoration: none;
     }

     button {
          @extend .toggle-plus;
          height: 20px;
          top: 0;
          right: 0;
          position: absolute;
          padding: 0;

          @include breakpoint(mw) {
               display: none;
          }
     }
}
.footer__nav-item {
     margin-bottom: .5rem;
     transition: all .2s;
     display: block;
     text-decoration: underline;

     &:hover, &:focus {
          color: $color-secondary;
     }
}
.footer__cta {
     display: flex;
     flex-direction: column;
     align-items: center;
     font-weight: $fontWeight-semibold;
     @include breakpoint(mw) {
          width: 45%;
     }
     .btn.btn--plain {
          margin: $grid-gutter 0;
     }
}

.footer__social {
     display: flex;
     justify-content: center;
     margin-top: 1rem;
}

.footer__bbb {
     margin: 1rem 0;
}

.footer__btns {
     display: flex;
     justify-content: space-evenly;
     flex-direction: column;
     @include breakpoint(mw) {
          flex-direction: row;
          width: 100%;
          margin-top: $grid-gutter;
     }
}
.footer__icon {
     width: 2.5rem;
     height: 2.5rem;
     margin: 0 1rem;
     transition: all .2s;
     fill: $color-primary;

     &:hover {
          fill: $color-secondary;
     }

}
.footer__bottom {
     background-color: $color-primary;
     color: #fff;
     text-align: center;
     padding: 1rem 0;

     a {
          text-decoration: underline;
          color: #fff;
     }
}

.footer-copyright {
     @include x-rem(font-size, 14px);
     
     @include breakpoint(mw) {
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
     }

     &-text {
          &--desktop {
               display: none;
               @include breakpoint(mw) {
                    display: inline;
               }
          }
     }
     &-links {
          display: flex;
          justify-content: space-evenly;
          & > * {
              margin: 0 2rem;
          }
     }
}
.back-to-top {
     margin: $grid-row-gap auto 0;
     color: $color-primary;
     text-transform: uppercase;
     &::after {
          display: none;
     }
     
     @include breakpoint(mw) {
          display: none;
      }
}
.logo-footer {
     max-width: 400px;
}