.newsletter {
    background-color: $color-grey-dark-2;
    color: #fff;
    padding: 2rem;

    @include breakpoint(mw) {
        width: 100%;
        padding: 1rem 2rem;
    }

    &__heading {
        color: #fff;
        padding: 0;
        &::after {
            display: none;
        }
    }
}
.newsletter {
    
    .form {
        flex: 1 1 100%;
        @include breakpoint(mw) {
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        input {
            background-color: transparent;
            border: transparent;
            border-bottom: 3px solid #fff;
            color: currentColor;
            padding-left: 0;

            &:focus {
                outline: none;
                border-bottom-color: $color-primary;
            }
    
            &::placeholder {
                color: currentColor;
            }
            &:placeholder-shown {
                padding-left: 0;
            }
        }
        label {
            margin-left: 0;
        }
        button {
            width: 100%;
            color: #fff;
            border-color: #fff;
            text-transform: uppercase;
            font-weight: $fontWeight-semibold;
        }
        
        &__group {
            flex-wrap: wrap;
            &--input {
                @include breakpoint(mw) {
                    width: 75%;
                }
            }
            &--button {
                width: 50%;
                align-self: flex-end;
                margin-top: $grid-gutter;
                @include breakpoint(mw) {
                    margin: 0;
                    padding-left: $grid-gutter;
                    width: 25%;
                }
            }
        }
    }
}
.validation-summary-valid {
    ul {
        @include resetList;
    }
}
.validation-summary-errors {
    ul {
        @include resetList;
        color: #831219;
        font-weight: bold;
    }
}

/**********************
** Constant Contact Inline Form Styles
**********************/
.ctct-form-button {
    background-color: $color-secondary !important;
    border-color: $color-secondary !important;

    &:hover,
    &:active,
    &:focus {
        filter: brightness(1.2);
    }
}