.locations {
    margin-top: $grid-row-gap;
    width: 100%;
}
.locations-map__box {
    background-color: $color-grey-light-3;
    padding-top: 100%;
}
.locations-rail {
    .sortby {
        margin-top: 0;
        .sortby__box {
            margin-left: 0;
        }
    }
}
.locations-branch__box {
    overflow-y:auto;
    max-height: 678px;
    
    &::-webkit-scrollbar {
        width: 20px;
    }

    &::-webkit-scrollbar-track {
        border: 2px solid $color-grey-light-1;
        border-radius: 30px;
    }

    &::-webkit-scrollbar-thumb {
        background: $color-grey-light-1;
        border-radius: 30px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $color-grey-dark-2;
    }
}
.locations-branch {
    padding: 2rem;
    background-color: $color-grey-light-3;
    margin-right: $grid-gutter;
    color: $color-primary;
    &:not(:last-child) {
        margin-bottom: $grid-gutter;
    }
    &--active {
        background-color: $color-secondary;
        color: $color-invert;

        .btn--plain {
            color: $color-invert;
        }
        a {
            color: $color-invert;
        }
        svg {
            fill: currentColor;
        }
    }

    &__name {
        font-size: 20px;
        font-weight: $fontWeight-bold;
        text-transform: uppercase;
        margin-bottom: 1rem;
    }
    &__address, &__phone, &__hours {
        display: flex;
        align-items: start;
        margin-bottom: 10px;
        svg {
            width: 20px;
            height: 20px;
            margin-left: 0;
            margin-right: 10px;
            fill: currentColor;
        }
    }
    .btn--plain {
        width: 100%;
        text-transform: uppercase;
        margin-top: $grid-gutter;
    }
}
.locations-branch__btn-review {
    padding: 0;
    justify-content: none;
    text-align: center;

    .icon {
        width: 44px;
        height: 44px;
        background-color: $color-primary;
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .icon-text {
        flex: 1;
    }

    &:hover,
    &:active,
    &:focus {
        svg {
            filter: brightness(0) invert(1);
        }
        .icon {
            background-color: transparent;
        }
    }
}