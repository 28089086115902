.slider-section {
    position: relative;
    text-align: center;
    padding: 70px;
}

.slider {
    @include resetList;
    display: flex;
    max-width: 1640px;
    width: 100%;
    margin: 0 auto;
}

.slider__item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 100%;
}

.slider__part {
    max-width: 200px;
    justify-content: center;
    align-items: center;

    @at-root a#{&} {
        &:hover,
        &:focus {
            opacity: 0.7;
        }
    }
}

.slider__img {
    max-height: 100%;
}

.slider-btn {
    @extend %carousel-btn;

    &--prev { left: 20px; }
    &--next { right: 20px; }
}

@media (min-width: $bp-mw) {
    .slider__item {
        width: percentage(1/8);
        flex: 1 0 auto;
        padding: 0 10px;
    }
}