.custom-hawk {
    font-family: $font-primary !important;
    .hawk-results__listing {
        display: block;
    }
    .hawk-results__top-tool-row, .hawk-results__bottom-tool-row {
        .hawk-tool-row {
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
        }
        .hawk-tool-row__item {
            padding: 0 0 15px 0;
            margin: 0;
            width: 100%;
        }
        .hawk-pagination__controls {
            @include breakpoint(36em) {
                width: 70%;
                margin-right: 0;
                padding-right: 20px;
            }
            .pagination__link {
                flex: 1;
            }
        }
        .hawk-items-per-page {
            @include breakpoint(36em) {
                width: 30%;
            }
        }
    }
    .hawk-results__bottom-tool-row {
        .hawk-sorting {
            display: none;
        }
    }
    
    .blog-listing__title {
        word-break: normal;
    }
    input.hawk-pagination__input {
        border: 2px solid $color-grey-light-2 !important;
        font-family: $font-primary;
        font-weight: $fontWeight-bold;
        font-size: 100% !important;
        appearance: none;
        background-color: transparent;
        height: 50px !important;
        color: #000 !important;
        border-radius: 0 !important;
        vertical-align: middle;
        box-shadow: none !important;
    }
    .hawk-sorting {
        position: relative;
        display: flex;
        align-items: center;
        width: max-content;
        margin-left: auto;
        @include breakpoint(mw) {
        }

        &::after {
            @extend %arrow, %arrow--down;
            position: absolute;
            right: 1rem;
            color: $color-secondary;
            pointer-events: none;
        }
    }
    .hawk-sorting > .hawk-sorting__label {
        padding-left: 1rem;
        position: absolute;
        pointer-events: none;
        font-family: $font-primary;
    }
    .hawk-sorting > select {
        border: 2px solid $color-grey-light-2;
        padding: 0 35px 0 90px;
        cursor: pointer;
        font-family: $font-primary;
        font-weight: $fontWeight-bold;
        font-size: 100%;
        appearance: none;
        background-color: transparent;
        height: 50px;
        color: #000;
        border-radius: 0;
        vertical-align: middle;
        box-shadow: none;
        -webkit-appearance: none;
                appearance: none;

        &:active, &:focus {
            border-color: $color-secondary;
        }
    }
    .hawk-items-per-page {
        position: relative;
        display: flex;
        align-items: center;
        width: max-content;
        margin-left: auto;
        @include breakpoint(mw) {
            margin-left: auto;
        }

        &::after {
            @extend %arrow, %arrow--down;
            position: absolute;
            right: 1rem;
            color: $color-secondary;
            pointer-events: none;
        }

        & > select {
            border: 2px solid $color-grey-light-2;
            padding: 0 35px 0 10px;
            cursor: pointer;
            font-family: $font-primary;
            font-weight: $fontWeight-bold;
            font-size: 100%;
            appearance: none;
            background-color: transparent;
            height: 50px;
            color: #000;
            border-radius: 0;
            vertical-align: middle;
            box-shadow: none;
            -webkit-appearance: none;
                    appearance: none;
                    
            &:active, &:focus {
                border-color: $color-secondary;
            }
        }
    }

    // facet styles
    &.facet {
        .hawk-facet-rail__selections, .hawk-results__bottom-tool-row, ul.hawk-facet-rail__facet-list {
            border: 0;
        }
        
        .hawk-results__top-tool-row {
            
        }
        .hawk__body {
            display: block; 
            @include breakpoint(mw) {
                display: flex;
                align-items: flex-start;
            }
        }
        .hawk-facet-rail {
            background-color: #fff;
            border: none;
            @include breakpoint(mw) {
                width: 300px;
            }
        }
        .hawk-facet-rail__facet {
            border: 0;
        }
        .hawk-facet-rail__facet-list {
            border: 2px solid $color-grey-light-1;
        }
        .hawk-facet-rail__heading {
            background-color: $color-secondary;
            border: none;
            margin-bottom: -2px;
            position: relative;
            z-index: 1;
            &:after {
                border-bottom: 15px solid #fff;
            }
        }
        .hawk-facet-rail__facet-values {
            padding: 0;
        }
        .hawk-facet-rail__facet-btn-exclude, .hawk-facet-rail__facet-btn-exclude
        .hawk-facet-rail, .hawk-facet-rail__facet-heading {
            h4 {
                color: $color-primary;
                font-size: 20px;
            }
            .icon path:first-child {
                fill: $color-secondary;
            }
            .hawk-facet-rail__facet-btn-include path {
                fill: #fff !important;
            }
        }
        .hawk-facet-rail__facet-btn-exclude {
            display: none;
            position: relative;
            z-index: 1;
            margin-left: 4px;
            &:hover, &:focus, &:active {
                box-shadow: none;
            }
        }
        .hawk-facet-rail__facet-values {
            border: none;
        }
        .hawk-selections__category-wrapper {
            background-color: $color-grey-light-4;
        }
        .hawk-selections__category-remove {
            background-color: $color-secondary;
        }
        .hawk-selections__category-remove .icon, .hawk-selections__item-remove .icon {
            path:first-child {
                fill: $color-secondary !important;
            }
        }
        .hawk-btn-primary-outline {
            &:hover, &:active, &:focus {
                background-color: $color-secondary;
                border-color: $color-secondary;
            }
        }
        ul.hawk-facet-rail__facet-list {
            display: flex;
            flex-wrap: wrap;
            
            li.hawk-facet-rail__facet-list-item {
                position: relative;
                padding: 6px 8px;
                margin-right: 8px;
                align-items: center;
                &:hover {
                    opacity: .8;
                }
            }
        }
        .hawk-facet-rail__facet-btn {
            margin: 0;
            &:hover, &:focus, &:active {
                color: inherit;
                border: none;
                box-shadow: none;
                .hawk-facet-rail__facet-name {
                    box-shadow: none;
                }
            }
        }
        span.hawk-facet-rail__facet-checkbox {
            width: 100%;
            height: 100%;    
            top: 0;
            left: 0;
            position: absolute;
            border: 2px solid $color-grey-light-1;
            pointer-events: none;
            z-index: 0;
            border-radius: 0;
            svg {
                display: none;
            }
            &.hawk-facet-rail__facet-checkbox--checked {
                background-color: $color-secondary;
                border-color: $color-secondary;
                & + .hawk-facet-rail__facet-name {
                    color: #fff;
                }
            }
        }
        .hawk-facet-rail__facet-name {
            position: relative;
            z-index: 1;
            font-family: $font-primary;
            font-weight: 600;
            font-size: 14px;
            word-break: break-word;
            color: $color-primary;
        }
        .hawk-facet-rail__facet__quick-lookup {
            display: none;
        }
        input {
            border-radius: 0 !important;
            font-family: $font-primary;
        }
        .slider-progressbar {
            background-color: $color-secondary;
        }
        .slider {
            margin: 0;
        }
    }

    .hawk-results__listing h1,
    .hawk-results__listing h2,
    .hawk-results__listing h3,
    .hawk-results__listing h4,
    .hawk-results__listing h5,
    .hawk-results__listing h6,
    .hawk-results__listing p,
    .hawk-results__listing a,
    .hawk-results__listing ul,
    .hawk-results__listing ol {
        word-break: normal;
    }

    .hawk-results__top-tool-row .hawk-tool-row__item {
        width: auto;
        margin-left: 20px;
    }
    .hawk-results__top-tool-row .hawk-tool-row {
        flex-direction: row;
        justify-content: flex-end;
    }
    .hawk-results__top-tool-row .hawk-pagination__controls {
        display: none;
    }
    .hawk-results__top-tool-row .hawk-items-per-page {
        width: 100%;
        max-width: 210px;
    }
}
.blog-listing__content.fullwidth {
    width: 100%;
}

.no-sort .hawk-results__top-tool-row {
    display: none;
}