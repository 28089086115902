.branch {
    margin-top: $grid-row-gap;
    display: flex;
    flex-direction: column;
    grid-gap: $grid-gutter;
    @include breakpoint(mw) {
        flex-direction: row;
    }
}
.branch__map {
    background-color: $color-grey-light-3;
    padding-top: 66.66%;
    @include breakpoint(mw) {
        max-width: 768px;
        width: 69%;
        padding-top: 39%;
        @include ie() {
            margin-right: $grid-gutter;
        }
    }
}
.branch__info {
    display: flex;
    flex-direction: column;
    grid-gap: $grid-gutter;
    color: $color-primary;
    @include breakpoint(mw) {
        width: 31%;
    }
}
.branch__meta {
    background-color: $color-grey-light-3;
    padding: 20px;
    display: flex;
    flex-grow: 1;
    @include ie() {
        &:not(:last-child) {
            margin-bottom: $grid-gutter;
        }
    }

    svg {
        fill: $color-secondary;
        width: 60px;
        height: 60px;
    }

    strong {
        text-transform: uppercase;
    }
}