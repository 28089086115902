/* ================================================================
   Variables
   ================================================================ */

// Color Variables
$body-bg: #fff;
$color-base: #000;
$color-invert: #fff;
$color-primary: #42444C;
$color-secondary: #C01823;
$color-grey-dark-1: #33353C;
$color-grey-dark-2: #707070;
$color-grey-light-1: #D9D9DB;
$color-grey-light-2: #D6D6D6;
$color-grey-light-3: #EBECED;
$color-grey-light-4: #F8F8F8;


// Typography
$font-primary: 'Montserrat', sans-serif;
$font-secondary: 'Open Sans', sans-serif;
$font-heading: $font-primary;

$fontWeight-light: 300;
$fontWeight-normal: 400;
$fontWeight-medium: 500;
$fontWeight-semibold: 600;
$fontWeight-bold: 700;
$fontWeight-extrabold: 800;

$typography-margin-bottom: 1em;


// Breakpoints
// You can convert these at http://pxtoem.com
$bp-s: 20em; // 320px
$bp-s-max: 29.938em; // 479px
$bp-sw: 30em; // 480px
$bp-sw-max: 47.938em; // 767px
$bp-m: 48em; // 768px
$bp-m-max: 59.938em; // 959px
$bp-mw: 60em; // 960px
$bp-mw-max: 71.188em; // 1139px
$bp-l: 71.25em; // 1140px
$bp-l-max: 81.188em; // 1299px
$bp-xl: 81.25em; // 1300px

// Transitions
$trans-duration: 0.18s;
$trans-ease: ease-in-out;
$trans: all $trans-duration $trans-ease;

// Grid
$container-size: 72.5rem; // 1160px
$grid-gutter: 15px;
$grid-gutter-em: 0.938em; // Changing grid gutter to em causes a compiling error :/
$grid-row-gap: 3rem;
