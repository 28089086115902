/* ================================================================
   MIXINS
   ================================================================ */




/* ================================================================
   REM Mixin
   ================================================================ */

// usage: @include x-rem(font-size, 14px)
// usage: @include x-rem(margin, 0 12px 2 1.2)
// usage: @include x-rem(padding, 1.5 24px)
//
// thanks to Eric Meyer for https://github.com/ericam/susy
// and Hans Christian Reinl for http://drublic.de/blog/rem-fallback-sass-less/

@mixin x-rem($property, $values) {
  // Create a couple of empty lists as output buffers.
  $base-font-size: 16px; // should be consistent with your html/body font-size
  $px-values: ();
  $rem-values: ();

  // Loop through the $values list
  @each $value in $values {
    // For each property value, if it's in rem or px, derive both rem and
    // px values for it and add those to the end of the appropriate buffer.
    // Ensure all pixel values are rounded to the nearest pixel.
    @if $value == 0 or $value == 0px {
      // 0 -- use it without a unit
      $px-values: join($px-values, 0);
      $rem-values: join($rem-values, 0);
    } @else if type-of($value) == number and not unitless($value) and (unit($value) == px) {
      // px value given - calculate rem value from base-font-size
      $new-rem-value: $value / $base-font-size;
      $px-values: join($px-values, round($value));
      $rem-values: join($rem-values, #{$new-rem-value}rem);
    } @else if type-of($value) == number and not unitless($value) and (unit($value) == "%") {
      // % value given - don't add px or rem
      $px-values: join($px-values, #{$value});
      $rem-values: join($rem-values, #{$value});
    } @else if $value == auto {
      // auto - don't add px or rem
      $px-values: join($px-values, auto);
      $rem-values: join($rem-values, auto);
    } @else {
      // unitless value - use those directly as rem and calculate the px-fallback
      $px-values: join($px-values, round($value * $base-font-size));
      $rem-values: join($rem-values, #{$value}rem);
    }
  }

  // output the converted rules
  #{$property}: $px-values;
  #{$property}: $rem-values;
}

/* ================================================================
   CLEARFIX MIXIN

   - EXAMPLE:

   div {
     @include clearfix;
   }
   ================================================================ */

@mixin clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

/* ================================================================
RESET STYLES BUTTON MIXIN

- EXAMPLE:

button {
  @include resetBtn;
}
================================================================ */

@mixin resetBtn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: $trans;
}

/* ================================================================
RESET STYLES LIST MIXIN

- EXAMPLE:

ul {
  @include resetList;
}
================================================================ */

@mixin resetList {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* ================================================================
INPUT PLACEHOLDER MIXIN

- EXAMPLE:

input {
  @include placeholder {
    color: red;
    opacity: 1;
  }
}
================================================================ */

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}

/* ================================================================
   PSUEDO MIXIN

   - EXAMPLE:

   div::after {
     @include pseudo;
     top: -1rem; left: -1rem;
   }
   ================================================================ */

@mixin pseudo($display: block, $pos: absolute, $content: ''){
    content: $content;
    display: $display;
    position: $pos;
}





/* ================================================================
   Breakpoint mixin
   ================================================================ */

@mixin breakpoint($point) {
  @if $point == retina {
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { @content; }
  }
  // 16px baseline (256px) s = small
  @else if $point == s {
    @media (min-width: $bp-s) { @content; }
  }
  // 16px baseline (580px) sw = small-wide
  @else if $point == sw {
    @media (min-width: $bp-sw) { @content; }
  }
  // 16px baseline (580px - 767px) sw-m = small-wide TO medium
  @else if $point == sw-m {
    @media (min-width:$bp-sw) and (max-width:$bp-m)  { @content; }
  }
  // 16px baseline (256px - 767px) s-m = small TO medium
  @else if $point == s-m {
    @media (min-width:$bp-s) and (max-width:$bp-m)  { @content; }
  }
  // 16px baseline (256px - 959px) s-l = small TO large
  @else if $point == s-l {
    @media (min-width:$bp-s) and (max-width:$bp-l)  { @content; }
  }
  @else if $point == s-xl {
    @media (min-width:$bp-s) and (max-width:$bp-xl)  { @content; }
  }
  // 16px baseline (768px) m = medium
  @else if $point == m {
    @media (min-width: $bp-m) { @content; }
  }
  // 16px baseline (960px) mw = medium-wide
  @else if $point == mw {
    @media (min-width: $bp-mw) { @content; }
  }
  // 16px baseline (768px - 959px) m-l = medium TO large
  @else if $point == m-l {
    @media (min-width:$bp-m) and (max-width:$bp-l)  { @content; }
  }
  // 16px baseline (768px - 1024px) m-lg = medium TO large
  @else if $point == m-lg {
    @media (min-width:$bp-m) and (max-width:$bp-lg)  { @content; }
  }
  // 16px baseline (768px - 1152) m-l = medium TO xlarge
  @else if $point == m-xl {
    @media (min-width:$bp-m) and (max-width:$bp-xl)  { @content; }
  }
  // 16px baseline (960px) l = large
  @else if $point == l {
    @media (min-width: $bp-l) { @content; }
  }
  // 16px baseline (1024px) lg = large
  @else if $point == lg {
    @media (min-width: $bp-lg) { @content; }
  }
  // 16px baseline (1152px) lx = extra large
  @else if $point == xl {
    @media (min-width: $bp-xl) { @content; }
  }

  @else {
    @media (min-width: $point) { @content; }
  }
}


/* ================================================================
   CUSTOM
   ================================================================ */

@function sqrt($r) {
  $x0: 1;
  $x1: $x0;

  @for $i from 1 through 10 {
    $x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0);
    $x0: $x1;
  }

  @return $x1;
}

// Get a straight px-to-rem value
// usage: pxtorem(5px)

@function pxtorem($size, $base: 16px) {
  $remSize: $size / $base;
  @return #{$remSize}rem;
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@function convert($value, $convertUnit){
  $currentUnit: unit($value);

  @if $currentUnit == $convertUnit {
    @return $value;
  }

  $value: strip-unit($value);

  @if $currentUnit == px{

    @if $convertUnit == em{
      @return $value / 16 + 0em;
    }
    @else if $convertUnit == percent{
      @return percentage($value / 16);
    }

  }@else if $currentUnit == em{

    @if $convertUnit == px{
      @return $value * 16 + 0px;
    }
    @else if $convertUnit == percent{
      @return percentage($value);
    }

  }@else if $currentUnit == percent{

    @if $convertUnit == px{
      @return $value * 16 / 100 + 0px;
    }
    @else if $convertUnit == em{
      @return $value / 100 + 0em;
    }

  }@else if $currentUnit == pts{

    @if $convertUnit == px{
      @return $value * 1.3333 +0px;
    }
    @else if $convertUnit == em{
      @return $value / 12 + 0em;
    }
    @else if $convertUnit == percent{
      @return percentage($value / 12)
    }
  }
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $min-vw: convert($min-vw, px);
  $max-vw: convert($max-vw, px);
  $min-font-size: convert($min-font-size, px);
  $max-font-size: convert($max-font-size, px);

  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@mixin lazy-fade-in($color: #f5f5f5) {
  &.b-lazy,
  &.b-lazy-wrapper {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background: $color;
        transition: $trans;
    }

    &.b-loaded::before,
    &.b-lazy-wrapper-done::before {
        background: transparentize($color: $color, $amount: 1)
    }
  }
}

%triangle-top-right {
  content: '';
  display: block;
  position: absolute;
  width: 8rem;
  height: 3rem;
  top: 0;
  right: 0;
  background-color: $color-secondary;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
}

%overlay {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0, .6);
}
%arrow {
  content: '';
  border: solid currentColor;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  position: relative;
  &--right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  &--left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  &--up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  &--down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

// Internet Explorer 10+ only
@mixin ie() {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}
.m0  { margin:        0!important }
.mt0 { margin-top:    0!important }
.mr0 { margin-right:  0!important }
.mb0 { margin-bottom: 0!important }
.ml0 { margin-left:   0!important }

.m40  { margin:        40px !important }
.mt40 { margin-top:    40px !important }
.mr40 { margin-right:  40px !important }
.mb40 { margin-bottom: 40px !important }
.ml40 { margin-left:   40px !important }