/* ================================================================
Base styles
================================================================ */

.site-container {

}

.section-container {

    &--large {
        padding: 100px 0;
    }

    &--medium {
        padding: 50px 0;
    }

    &--small {
        padding: 20px 0;
    }

    &--no-padding {
        padding:0;
    }
}    