/* ================================================================
   Hero Slider and Static
   ================================================================ */

.hero-slider-container {
    position: relative;
}

.hero-slider {
    transition: $trans;
    max-width: $bp-xl;
    margin: 0 auto;
}
.hero-slide-controls {
    display: none;
    @include breakpoint(mw) {
        position: absolute;
        bottom: 59px;
        right: 0;
        background: rgba(0,0,0,.8);
        padding: 0 4rem 0 1rem;
        width: 200px;
        height: 35px;
        display: flex;
    }
}
.hero-slider-btn {
    @extend %slider-btn;
    color: #fff;
    position: absolute;
    top: 40%;

    &--mobile {
        @extend %slider-btn--mobile;
    }

    &--prev {
        @extend %slider-btn--prev; 
        left: 0; 
    }
    &--next { 
        @extend %slider-btn--prev; 
        right: 0; 
    }
    &--pause {
        @extend %slider-btn--pause; 
        margin-left: 1rem; 
        top: inherit; 
        bottom: 55px; 
        right: 20px; 
        z-index: 3;
        display: none;

        @include breakpoint(400px) {
            display: block;
        }
    }

    @include breakpoint(mw) {
        position: static;
        top: 0;

        svg {
            width: 1rem;
            height: 1rem;
            fill: #fff;
        }
    }
}

.hero-slide {
    display: flex;
    flex-direction: column;

    @include breakpoint(mw) {
        flex-direction: row;
    }
    &__image-box {
        overflow: hidden;
        position: relative;
        flex: 1 1 55%;
        
        @include breakpoint(mw) {
            max-height: 60vh;
            &:before {
                content: '';
                display: block;
                position: absolute;
                clip-path: polygon(0 0, 100% 0, 100% 100%, 95% 100%);
                background: #fff;
                height: 100%;
                width: 20%;
                right: -1px;
                z-index: 3;
                @include ie() {
                    display:none;
                }
            }
        }

        .carousel__slider-btn {
            z-index: 4;
            top: 20%;

            @include breakpoint(400px) {
                top: 40%;
            }
        }

        
    }

    &__image  {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        margin: 0;
    }

    &__container {
        background: #fff;
        padding: 0 2rem;
        display: flex;
        align-items: center;
        z-index: 3;
        flex: 1 1 45%;
        position: relative;
    }

    &__cta {
        position: absolute;
        width: 100%;
        bottom: 0;
        display: flex;
        background-color: $color-grey-dark-2;
        z-index: 2;

        &:before, &:after {
            content: '';
            position: absolute;
            display: block;
            clip-path: polygon(0 0, 100% 100%, 0 100%);
            background-color: rgba($color-secondary, .8);
            width: 100%;
            height: 70%;
            top: -50%;
            @include breakpoint(mw) {
                display: none;
            }
        }
        &:before {
            clip-path: polygon(100% 0, 100% 100%, 0 100%);
            background-color: $color-grey-dark-2;
            z-index: 2;
            top: -49%;
        }

        a {
            text-transform: uppercase;
            color: #fff;
            font-weight: $fontWeight-bold;
            font-size: 1.5rem;
            text-align: center;
            flex: 1;
            margin: .8rem;

            &:not(:last-child) {
                border-right: 3px solid currentColor;
            }
        }
    }
}
.hero-slide-info-box {
    margin: 3rem auto 1rem;

    @include breakpoint(mw) {
        margin: 0;
    }

    &__title {
        font-size: 28px;
        font-weight: $fontWeight-extrabold;
        text-align: center;
        text-transform: uppercase;
        @include breakpoint(mw) {
            font-size: 40px;
            text-align: left;
        }
        span {
            color: $color-secondary;
        }
    }
    &__subtitle {
        margin: 2rem 0;
    }
}
.hero-slide-info-box__btn {
    display: none;
    @include breakpoint(mw) {
        display: flex;
        color: $color-secondary;
        font-weight: $fontWeight-bold;
    }
}
.hero-slide__item {
    width: 100%;
    max-height: 460px;
}
.hero-slide__figure {
    background-size: cover;
    background-position: center;
    height: 400px;
}