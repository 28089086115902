/* ================================================================
   News - Majority of styling is done as part of the lists.scss
   ================================================================ */

.news {
    background-color: $color-grey-light-3;
    position: relative;
    padding: 3rem 2rem;

    @include breakpoint(mw) {
        width: 100%;
        height: 100%;
        padding: 4rem 2rem 3rem;
    }

    &::after {
        @extend %triangle-top-right;
        z-index: 1;
    }

    &__list {
        @include resetList;
    }
    &__item {
        &:not(:last-child) {
            margin-bottom: 2rem;
        }
    }
    &__link {
        font-weight: $fontWeight-semibold;
        text-decoration: underline;
        display: block;

        &:visited, &:hover, &:focus {
            opacity: .7;
        }
    }

    .btn--link {
        margin-top: 2rem;
        margin-left: auto;
        @include breakpoint(mw) {
            justify-content: start;
            align-self: flex-end;
            margin-left: 0;
        }
    }
}

