/* ================================================================
   Typography Styles
   ================================================================ */


h1, .h1, h2, .h2, h3, .h3 {
	margin:0 0 $typography-margin-bottom;
	font-family:$font-heading;
	color: $color-primary
}

h1, .h1 { 
	@include x-rem(font-size, 28px);
    @include breakpoint(mw) {
        @include x-rem(font-size, 37px);
    }
 }
h2, .h2 { 
	@include x-rem(font-size, 23px);
    @include breakpoint(mw) {
        @include x-rem(font-size, 30px);
    }
 }
h3, .h3 { 
	@include x-rem(font-size, 18px);
    @include breakpoint(mw) {
        @include x-rem(font-size, 23px);
    }
 }
h4, .h4 { font-family: $font-primary }

.heading-line {
	text-transform: uppercase;
	color: $color-primary;
	position: relative;
	padding-bottom: 1rem;
	&:after {
		content: '';
		width: 100%;
		max-width: 180px;
		height: 5px;
		background-color: $color-secondary;
		position: absolute;
		left: 0;
		bottom: 0;
	}
}

a {
	color:$color-primary;
	text-decoration:none;
	transition: $trans;

	&:hover, &:focus { text-decoration:underline; }
}

p { margin:0 0 $typography-margin-bottom; }

// Very simple blockquote styling
blockquote {
	margin:1em 1.5em; padding-left:1.5em;
	border-left:5px solid hsl(0, 0%, 85%);
}

.smallest { font-size:0.8em; }
.smaller { font-size:0.9em; }
.larger { font-size:1.1em; }
.largest { font-size:1.2em; }

.bold { font-weight:bold; }
.italic { font-style:italic; }
.strike { text-decoration:line-through; }

.nowrap { white-space:nowrap; }
.important { color:red; font-weight:bold; text-transform:uppercase; }

.title {
	@include fluid-type($bp-s, $bp-mw, 20px, 30px);
	font-weight: $fontWeight-semibold;
	margin: 0 0 5px;
}

.fr-view {
	p {
		a {
			color: $color-secondary;
		}
	}
}