/* ================================================================
   Button Styles
   ================================================================ */

.button,
.btn {
	display:inline-block;
	padding:0.6em 1.2em;
	border:0;
	font-family:$font-primary;
	color:$color-invert;
	text-decoration:none; text-align:center;
	background:$color-grey-dark-2;
	cursor: pointer;
    transition: $trans;
    line-height:1.4;
	
	svg {
		fill: currentColor;
		width: 1rem;
		height: 1rem;
		display: block;
	}

	&:hover, &:focus {
		text-decoration:none;
		background:$color-secondary;
	}

	&--active {
		background-color: $color-secondary;
		color: $color-invert;
		border: none;
		margin: auto;

		&:hover, &:focus {
			opacity: .7;
		}
	}
	&--plain {
		background-color: transparent;
		color: $color-primary;
		border: 2px solid currentColor;

		&:hover, &:focus {
			background:$color-secondary;
			color: $color-invert;
		}
	}
	&--link {
		background-color: transparent;
		color: $color-secondary;
		border: none;
		font-weight: $fontWeight-bold;
		display: flex;
		align-items: center;
		padding: 0;
		width: auto;
		text-align: left;

		&::after {
			@extend %arrow, %arrow--right;
			margin-left: 10px;
			transition: $trans;
		}

		&:hover, &:focus {
			background-color: transparent;
			opacity: .7;
			&::after {
				transform: translateX(5px) rotate(-45deg);
			}
		}
	}
	&--icon {
		display: inline-flex;
		gap: 5px;
		align-items: center;
		justify-content: center;

		svg {
			width: 20px;
			height: 20px;
			fill: currentColor;
			flex-shrink: 0;
		}
	}
}
.button-2 {
	display:inline-block;
	padding:0.47em 1.2em;
	border:0;
	font-family:$font-primary;
	color:$color-invert;
	text-decoration:none; text-align:center;
	background:$color-secondary;
	cursor: pointer;
    transition: $trans;
    line-height:1.4;

	&:hover, &:focus {
		text-decoration:none;
        background:$color-invert;
        color:$color-primary;
	}
}

.back {
	@extend .button-small !optional;
	text-transform: capitalize;
}

.more {
	@extend .button-small !optional;
	text-transform:capitalize;
}

%carousel-btn {
    @include resetBtn;
	position: absolute;
    width: 60px;
    height: 60px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    font-size: 0;
    color: $color-primary;
    transition: $trans;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;

    svg {
        display: inline-block;
        width: 100%;
        height: 100%;
        fill: currentColor;
        transition: $trans;
    }

    &:hover,
    &:focus {
        color: $color-secondary;
	}

	@media (min-width: $bp-mw) {
		width: 60px;
		height: 60px;
	}
}
.toggle-plus {
	display:block;
	border:0;
	background:none;
	position: relative;
	cursor:pointer;
    width: auto;

	&[data-expander-toggle] {
		padding-right: 2rem;

		&:before, &:after {
			content: '';
			display: block;
			width: 14px;
			height: 4px;
			top: 0;
			bottom: 0;
			margin: auto;
			right: 1rem;
			background-color: $color-secondary;
			position: absolute;
			transition: all .2s;
			transform: translateY(-55%) translateX(10px) rotate(0deg);
			
			@include breakpoint(mw) {
				right: 1rem;
		   }
		}
		&:after {
			transform: translateY(-55%) translateX(10px) rotate(90deg);
			transition: all .2s;
		}
		
		
		&.active:after {
			transform: translateY(-55%) translateX(10px) rotate(0deg);
			transition: all .2s;
		}
	}
}

.toggle-chevron {
	display:block;
	border:0;
	background:none;
	cursor:pointer;

	&:hover,
	&:focus {
		text-decoration: none;
		color: $color-secondary;
	}
	&.active {
		color: $color-secondary;  
	}

	&[data-expander-toggle] {

		&:after {
			display: inline-block;
			border-right: 4px solid currentColor;
			border-bottom: 4px solid currentColor;
			width: 10px; height: 10px;
			transform:  translateY(-55%) translateX(8px) rotate(45deg);
			background-color: transparent;
			cursor:pointer;
		}
		&:before {
			display: none;
		}

		&.active:after {
			transform: translateY(0) translateX(8px) rotate(225deg);
			transition: all .2s;
		}
	}
}
.btn--play {
	position: relative;
	display: flex;
	color: $color-secondary;
	padding-left: 3rem;
	&::before {
		content: '';
		width: 2rem;
		height: 2rem;
		position: absolute;
		margin: auto;
		top: 0;
		bottom: 0;
		left: 0;
		border-radius: 20rem;
		border: 2px solid currentColor;
	}
	&:after {
		content: '';
		width: 0; 
		height: 0;
		position: absolute;
		margin: auto;
		top: 0;
		bottom: 0;
		left: 13px;
		border-top: 7px solid transparent;
		border-bottom: 7px solid transparent;
		border-left: 11px solid currentColor;
	}
}
.btn--search-landing {
	background-color: $color-secondary;
	border: none;
	color: #fff;
	width: 44px;
	height: 44px;
	display: flex;
	justify-content: center;
    align-items: center;
	padding: 10px;
	svg {
		width: 100%;
		height: 100%;
	}
}

%slider-btn {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 1;
	color: $color-primary;
	justify-content: center;
	padding: 0;
	margin: auto 0;
	transition: all .2s;

    svg {
        width: 1rem;
        height: 1rem;
        fill: currentColor;
		&:hover, &:focus {
			opacity: .7;
		}
    }

	&--pause {
		box-sizing: border-box;
		height: 16px;
		border-color: transparent transparent transparent currentColor;
		transition: .2s all ease;
		will-change: border-width;
		cursor: pointer;
		align-self: center;
		padding: 0;
		// play state
		border-style: double;
		border-width: 0px 0 0px 15px;
		// paused state
		&.pause {
			border-style: solid;
			border-width: 8px 0 8px 15px;
		}
		&:hover, &:focus {
			opacity: .7;
		}
	}
	&--mobile {
		background-color: rgba(0,0,0, .8);
		color: #fff;
		width: 35px;
		height: 35px;
		svg {
			height: 18px;
			fill: currentColor;
		}
		&:hover, &:focus {
			opacity: .7;
		}
		@include breakpoint(mw) {
			background-color: transparent;
			color: $color-grey-dark-2;
		}
	}
    &--prev { padding: 0; }
    &--next { padding: 0; }
	&--dots {
		@include resetList;

		li {
			border: solid 2px currentColor;
			border-radius: 50%;
			width: .8rem;
			height: .8rem;
			cursor: pointer;
			margin: 0 .5rem;
			transition: all .2s;

			&:hover, &:focus {
				border-color: currentColor;
				box-shadow: inset 0 0 2px #444;
			}

			&.slick-active {
				background-color: currentColor;
				border: solid 2px currentColor;
			}

			button {
				line-height: 0;
				font-size: 0;
				height: 0.8rem;
				width: 0.8rem;
				position: absolute;
				border: none;
				background: transparent;
				cursor: pointer;
			}
		}
	}
}