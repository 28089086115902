.about {
    background-position: center;
    background-size: cover;
    position: relative;
    padding: 3rem 2rem;

    @include breakpoint(mw) {
        width: 100%;
        height: 100%;
        padding: 4rem 2rem 3rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 400px;
    }
    
    &::before {
        @extend %overlay;
        z-index: 0;
    }
    &::after {
        @extend %triangle-top-right;
        z-index: 1;
    }

    &__heading {
        color: #fff;
    }

    &__text {
        color: #fff;
        position: relative;
    }
    &__more {
        color: #fff;
        position: relative;
        font-weight: $fontWeight-semibold;
        margin-left: auto;

        @include breakpoint(mw) {
            justify-content: start;
            margin-top: 3rem;
            margin-left: 0;
        }
    }
}