.catalog-interior, .facet .hawk-results__listing {
    @include resetList;
    display: grid !important;
    grid-gap: $grid-gutter;
    grid-template-columns: repeat(2, 1fr);
    @include breakpoint(mw) {
        grid-template-columns: repeat(3, 1fr);
        &.grid-3 {
            grid-template-columns: repeat(3, 1fr);
        }
        &.grid-4 {
            grid-template-columns: repeat(4, 1fr);
        }
    }
    @include ie() {
        display: flex;
        flex-wrap: wrap;
        &__item {
            width: calc(33% - 10px);
            margin-top: 12px;
            margin-right: 12px;
        }
    }
    &__link {
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;
        display: block;
        .aspect-ratio {
            padding-top: 66.66%; /* 3:2 Aspect Ratio (divide 2 by 3 = 0.6666)  */
        }
    }
    &__sort {
        background-color: rgba($color-grey-dark-2, .8);
        padding: 10px 0 10px 10px;
        color: #fff;
        font-weight: $fontWeight-bold;
        text-transform: uppercase;
        position: absolute;
        bottom: 0;
        width: 100%;
        font-size: 10px;
        @include breakpoint(mw) {
            font-size: 1rem;
        }
    }
    &__category {
        font-weight: $fontWeight-semibold;
        font-size: 14px;
        color: $color-grey-dark-2;
        margin: calc(#{$grid-gutter}/2) 0;
        @include breakpoint(mw) {
            margin: $grid-gutter 0;
        }
    }
    &__title {
        font-size: 18px;
        color: $color-primary;
        font-weight: $fontWeight-bold;
        text-decoration: underline;
        @include breakpoint(mw) {
            font-size: 20px;
        }
    }
    &__price {
        color: $color-grey-dark-2;
        font-weight: $fontWeight-bold;
        margin: calc(#{$grid-gutter}/2) 0;
    }
    a {
        display: block;
        &:hover {
            text-decoration: none;
            opacity: .8;
        }
    }
}
.pagination--catalog {
    margin: $grid-gutter 0;
    .pagination__list {
        justify-content: center;
        align-items: center;
    }
    .pagination__link {
        font-size: 0;
        color: $color-secondary;
        @include breakpoint(mw) {
            font-size: 20px;
        }
        &.disabled {
            color: $color-grey-dark-2;
        }
    }
    .pagination__pages {
        margin: 0 $grid-gutter;
        @include breakpoint(mw) {
            margin: 0 $grid-row-gap;
        }
    }
}