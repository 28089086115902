/* ================================================================
   Filters
   ================================================================ */

.filters {
    margin:0 0 1rem;
    padding:15px;
    background:#eee;
}

.filter {
    margin:0 0 15px;

    .button {
        width:100%;
        white-space: nowrap;
    }

    &:last-child {
        margin:0;
    }
}

.filter__label {
    display:block;
    margin:0 0 3px;
    white-space: nowrap;
}
.filter__label--date {
    font-size:.875em;
    padding:0 5px 0 0;
}

.filter__select {
    width:100%;
}

.filter__input {
    width:100%;
}
.filter__input--date {
    width:70px;
}

.filter__fieldset {
    margin:0 0 15px;
}

.filter__legend {
    display:block;
    margin:0 0 3px;
    width:100%;
}

.filter__fieldset-content {
    display:flex;
}

.filter__inner-field {
    padding:0 10px 0 0;
}

.filter__search-content {
    position:relative;
}

.filter__search-button {
    position:absolute;
    background:none;
    border:0;
    padding:0;
    width:20px;
    height:20px;
    top:50%;
    right:10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: $color-primary;

    &:hover,
    &:focus {
        color: $color-secondary;
    }

    svg {
        fill: currentColor;
        width:100%;
        height:100%;
        transition: $trans;
    }
}

.filter--nolabel {
    &::before {
        @extend .filter__label;
        content: '\a0'; // non breaking space
    }
}

@media (min-width: $bp-l) {
    .filters {
        display:flex;
        padding:15px 5px;
        justify-content: space-between;
    }

    .filters--stacked {
        display: block;

        .filter {
            margin-bottom: 10px;
        }
    }

    .filter {
        padding:0 10px;
        width:100%;
        margin:0;
    }

    .filter--date {
        display:flex;
    }

    .filter__fieldset {
        margin:0;
    }

    .filter__fieldset-content {
        justify-content: space-between;
    }

    .filter__inner-field {
        display:flex;
        align-items:center;
        padding:0 10px 0 0;
    }
}

.sortbyToggle {
    margin-left: 0;
    margin-right: auto;
    background: transparent;
    border: 2px solid $color-grey-light-2;
    font-family: $font-primary;
    padding: 8px 10px;
    font-weight: $fontWeight-bold;
    display: flex;
    align-items: center;
    cursor: pointer;
    @include breakpoint(mw) {
        display: none;
    }
    svg {
        margin-right: 5px;
        width: 25px;
        height: 25px;
    }
}
.sortby {
    display: flex;
    flex-direction: column;
    margin: $grid-gutter 0;
    grid-gap: $grid-gutter;
    @include breakpoint(mw) {
        justify-content: flex-end;
    }
    &-expander {
        @include breakpoint(mw) {
            display: block;
        }
    }
    &__box {
        position: relative;
        display: flex;
        align-items: center;
        width: max-content;
        @include breakpoint(mw) {
            margin-left: auto;
        }

        &::after {
            @extend %arrow, %arrow--down;
            position: absolute;
            right: 1rem;
            color: $color-secondary;
            pointer-events: none;
        }
    }
    &__label {
        padding-left: 1rem;
        position: absolute;
        pointer-events: none;
    }
    &__select {
        border: 2px solid $color-grey-light-2;
        padding: 0 78px 0 90px;
        cursor: pointer;
        font-family: $font-primary;
        font-weight: $fontWeight-bold;
        appearance: none;
        background-color: transparent;
        height: 50px;
        -webkit-appearance: none;
                appearance: none;
        
        &:active, &:focus {
            border-color: $color-secondary;
        }

    }
}

//  Classes Filter
.classes-filter {
    display: flex;
    flex-direction: column;
    @include breakpoint(mw) {
        flex-direction: row;
        justify-content: space-around;
    }
    &-item {
        display: flex;
        align-items: center;
        border: 2px solid $color-grey-light-1;
        margin-bottom: 4px;
        padding: 0 $grid-gutter;
        @include breakpoint(mw) {
            flex-direction: column;
            align-items: flex-start;
            margin: 0;
            flex: 1;
            padding: $grid-gutter;
            &:not(:last-child) {
                margin-right: $grid-gutter;
            }
        }
        label {
            flex: 1;
            font-size: 14px;
        }
        select {
            width: 100%;
            flex: 1;
            background-color: transparent;
            border: 0;
            font-weight: $fontWeight-bold;
            font-family: $font-primary;
            color: $color-primary;
            cursor: pointer;
            padding: 0;
            margin-left: -3px; // select alignment fix
            @include breakpoint(mw) {
                padding: 0;
            }
        }
    }
}