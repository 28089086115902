.carousel__slider-box {
    position: relative;
    margin: 0 (-$grid-gutter) $grid-gutter;
    @include breakpoint(mw) {
        margin: 0 0 $grid-gutter;
    }
}
.carousel__slider {
    @include resetList;
    @include breakpoint(mw) {
        padding: 0 5rem;
    }
}
.carousel-item {
    display: flex;
    padding: 0 calc(#{$grid-gutter}/2);
    flex-direction: column;
    text-decoration: underline;

    &__image-box {
        width: 100%;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        margin-bottom: $grid-gutter;
    }

    &:hover {
        color: $color-grey-dark-2;
    }
    &__name {
        font-weight: $fontWeight-semibold;
    }
    .aspect-ratio {
        padding-top: 66.66%; /* 3:2 Aspect Ratio (divide 2 by 3 = 0.6666)  */
    }
}
.carousel__slider-btn {
    @extend %slider-btn;
    position: absolute;
    top: 40%;

    svg {
        @include breakpoint(mw) {
            width: 20px;
            height: 30px;
        }
    }
	&--mobile {
        @extend %slider-btn--mobile;
	}
    &--prev {
        @include breakpoint(mw) {
            left: 2rem;
        }
    }
    &--next {
        right: 0;
        @include breakpoint(mw) {
            right: 2rem;
        }
    }
}