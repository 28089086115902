.cards {
    margin: $grid-row-gap auto;
}
.cards-box {
    margin: 0 (-$grid-gutter);
    @include breakpoint(mw) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: $grid-gutter;
        margin: auto;
        @include ie() {
            display: flex;
            flex-wrap: wrap;
            .card {
                width: calc(33% - 7px);
                &:not(:last-child) {
                    margin-right: $grid-gutter;
                }
            }
        }
    }
}
.card {
    position: relative;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    padding: 60px 30px 30px;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background-color: rgba(0,0,0, .6);
    }
    &::after {
        @extend %triangle-top-right;
    }
    &__heading {
        color: #fff;
        margin-bottom: 6rem;
        @include breakpoint(mw) {
            width: min-content;
            margin-bottom: 8rem;
        }
    }
    &__link {
        z-index: 2;
        color: #fff;
        margin-left: auto;
        @include breakpoint(mw) {
            margin-left: 0;
        }
    }
}