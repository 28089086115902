.solutions-wrapper {
    margin-top: $grid-row-gap;
}
.solutions {
    @include resetList;
    display: grid;
    grid-gap: $grid-gutter;
    @include breakpoint(mw) {
        grid-template-columns: repeat(4, 1fr);
    }
    @include ie() {
        display: flex;
        flex-wrap: wrap;
        &__item {
            width: calc(25% - 12px);
            margin-top: 12px;
            margin-right: 12px;
        }
    }
    &__link {
        background-size: cover;
        background-position: center center;
        position: relative;
        display: block;
        .aspect-ratio {
            padding-top: 66.66%; /* 3:2 Aspect Ratio (divide 2 by 3 = 0.6666)  */
        }
    }
    &__title {
        background-color: rgba($color-grey-dark-2, .8);
        padding: 10px 0 10px 10px;
        color: #fff;
        font-weight: $fontWeight-bold;
        text-transform: uppercase;
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}
.solutions-tabs-toggle {
    width: 100%;
    background-color: transparent;
    border: 2px solid $color-grey-dark-1;
    color: $color-grey-dark-1;
    padding: $grid-gutter;
    font-family: $font-primary;
    margin-bottom: $grid-gutter;
    text-align: left;
    font-size: 14px;
    font-weight: $fontWeight-semibold;
    cursor: pointer;
    position: relative;

    @include breakpoint(mw) {
        display: none;
    }
    &.active {
        border-color: $color-secondary;
    }

    &::after {
        @extend %arrow, %arrow--down;
        position: absolute;
        right: $grid-gutter;
        color: $color-grey-dark-1;
    }
}
.solutions-tabs-box {
    position: relative;
    margin: $grid-gutter 0;
}
.solutions-tabs {
    @include resetList;
    position: absolute;
    z-index: 1;
    background-color: #fff;
    width: 100%;
    left: 0;
    border: 2px solid $color-secondary;
    top: 48px;
    
    @include breakpoint(mw) {
        position: static;
        border: none;
        display: flex !important;
        grid-gap: $grid-gutter;
        @include ie() {
            &__item {
                &:not(:last-child) {
                    margin-right: $grid-gutter;
                }
            }
        }
    }

    &__item {
        flex: 1;
    }

    &__button {
        cursor: pointer;
        background-color: transparent;
        border: none;
        padding: 10px $grid-gutter;
        width: 100%;
        text-align: left;
        font-family: $font-primary;    
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint(mw) {
            flex: 1;
            background-color: transparent;
            color: $color-grey-dark-1;
            border: 2px solid $color-grey-dark-1;
            cursor: pointer;
            height: 70px;
            font-weight: $fontWeight-semibold;
            text-align: center;
            padding: 0 5px;
        }

        &:hover, &:focus {
            text-decoration: none;
        }

        &.active {
            font-weight: $fontWeight-bold;
            @include breakpoint(mw) {
                background-color: $color-grey-dark-1;
                color: #fff;
            }
        }
    }
}
.solutions-header {
    @include breakpoint(mw) {
        display: flex;
        grid-gap: $grid-gutter;
        .page-banner, .slider-product-single {
            width: 50%;
            @include ie() {
                &:not(:last-child) {
                    margin-right: $grid-gutter;
                }
            }
        }
    }
    .slider-product-single {
        margin-top: $grid-row-gap;
        @include breakpoint(mw) {
            margin-top: 0;
        }
    }
}

// Solutions Content
.solutions-content-wrapper {
    margin-top: $grid-row-gap;
    @include breakpoint(mw) {
        max-width: $bp-mw;
        margin-left: auto;
        margin-right: auto;
    }
}
.solutions-content {
    border: 2px solid $color-grey-dark-1;
    padding: $grid-row-gap;
    a {
        color: $color-secondary;
    }
}