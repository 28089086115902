.services {
    margin: $grid-row-gap 0;
    &__box {
        @include breakpoint(mw) {
            display: flex;
            flex-wrap: wrap;
        }
    }
    
    &__list {
        @include resetList;
        display: flex;
        flex-direction: column;

        @include breakpoint(mw) {
            width: 35%;
        }

        &-item {
            font-size: 1.1rem;
            text-align: center;
            
            @include breakpoint(mw) {
                margin-right: $grid-gutter;
                flex-grow: 1;
                font-size: 1.8rem;
            }

            &:not(:last-child) {
                margin-bottom: .5rem;
            }
            a {
                background-color: $color-grey-dark-2;
                color: $color-invert;
                text-transform: uppercase;
                font-weight: $fontWeight-bold;
                padding: .5rem;
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
                @include breakpoint(mw) {
                    justify-content: left;
                    padding-left: 2rem;
                }

                &:hover {
                    background-color: $color-secondary;
                    position: relative;
                    text-decoration: none;
                    &::after {
                        @extend %arrow, %arrow--right;
                        position: absolute;
                        right: 2rem;
                        width: 20px;
                        height: 20px;
                        border-width: 0 5px 5px 0;
                    }
                }
            }
        }
    }
}

.services__locations {
    display: flex;
    flex-direction: column;
    background-color: $color-grey-light-3;
    margin: 2rem (-$grid-gutter);
    padding: 2rem ($grid-gutter);
    position: relative;

    @include breakpoint(mw) {
        margin: 0;
        flex-direction: row;
        width: 65%;
        align-items: center;
    }

    &::after {
        @extend %triangle-top-right;
    }

    &-map {
        margin: 1rem auto;
        @include breakpoint(mw) {
            width: 50%;
            padding: 0 2rem;
            text-align: center;
        }
    }

    &-box {
        @include breakpoint(mw) {
            width: 50%;
            display: flex;
            flex-direction: column;
        }
    }

    &-heading {
        margin: 1rem 0;
    }

    &-text {
        margin: 1rem 0 2rem;
    }

    .btn--link {
        margin-left: auto;

        @include breakpoint(mw) {
            margin-left: 0;
        }
    }
}