.blog-listing {
    @include resetList;

    &__item {
        margin-bottom: $grid-row-gap;
        display: flex;
        flex-direction: column;

        @include breakpoint(mw) {
            flex-direction: row;
            align-items: flex-start;
        }
    }

    &__image {
        background-size: contain;
        background-position: top center;
        background-repeat: no-repeat;
        position: relative;
        width: 100%;

        @include breakpoint(mw) {
            width: 50%;
        }

        .aspect-ratio {
            padding-top: 66.66%; /* 3:2 Aspect Ratio (divide 2 by 3 = 0.6666)  */
        }

        video {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        &:hover > .blog-listing__thumb-icon {
            transform: scale(1.2);
        }
    }

    &__thumb-icon {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 60px;
        height: 60px;
        color: #fff;
        border: 3px solid currentColor;
        border-radius: 100%;
        padding: 12px;
        background-color: rgba(0,0,0,.5);
        transition: $trans;

        .icon-play {
            left: 3px;
            position: relative;
        }

        svg {
            fill: currentColor;
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        @include breakpoint(mw) {
            margin-left: $grid-gutter;
            width: 50%;
        }
    }

    &__title {
        margin: 1rem auto .5rem;

        @include breakpoint(mw) {
            margin: 0 auto .5rem;
        }
    }

    &__meta {
        display: block;
        color: $color-secondary;
        text-transform: uppercase;
        font-weight: $fontWeight-bold;
        margin-bottom: 1rem;
    }

    &__more {
        margin-top: 1rem;
        margin-left: auto;

        @include breakpoint(mw) {
            margin-top: 2rem;
        }
    }
}

.col-md-6 .blog-listing__content {
    padding: 0;
}


.resources-list {
    .blog-listing__image {
        @include breakpoint(mw) {
            width: 45%;
        }

        .aspect-ratio {
            background-color: rgba(0,0,0, .3);
        }

        &.video-modal {
            .aspect-ratio {
                background-color: transparent;
            }
        }
    }

    .blog-listing__content {
        @include breakpoint(mw) {
            width: 55%;
        }
    }

    .blog-listing__content {
        @include breakpoint(mw) {
            padding: 0;
        }
    }
}

.col-md-6 {
    .blog-listing__item {
        flex-direction: column;
    }

    .resources-list .blog-listing__image {
        width: 100%;
        margin-bottom: 1rem;
    }

    .resources-list .blog-listing__content {
        width: 100%;
    }
}
