.contact-cta {
    margin: 3rem auto;
    
    &__box {
        background-color: $color-grey-dark-2;
        text-align: center;
        padding: $grid-gutter;
        margin: 0 (-$grid-gutter);
        @include breakpoint(mw) {
            margin: 0;
        }
    }
    &__heading {
        color: #fff;
        text-transform: uppercase;
    }
    &__btn {
        color: #fff;
        text-transform: uppercase;
        font-weight: $fontWeight-bold;
    }
}