.content-listing-section {
    margin: $grid-row-gap 0;
}
.content-listing {
    @include resetList;

    &__item {
        margin-bottom: $grid-row-gap;
        @include breakpoint(mw) {
            display: flex;
        }
    }

    &__image {
        @include breakpoint(mw) {
            width: 45%;
        }
    }
    &__content {
        margin-top: $grid-gutter;
        @include breakpoint(mw) {
            margin-top: 0;
            margin-left: $grid-gutter;
            width: 55%;
        }
    }
    &__more {
        margin-top: 1rem;
        margin-left: auto;
        @include breakpoint(mw) {
            margin-top: 1rem;
            margin-left: 0;
        }
    }
}