/* ================================================================
   Variables
   ================================================================ */

$input-height: 50px !default;
$input-background: #ffffff !default;
$input-border-color: $color-grey-light-1 !default;
$input-border-color-selected: $color-primary !default;
$input-border-width: 2px !default;
$input-padding: 5px 10px;
$form-spacing: 20px;
$note-size: 14px;
$error-color: $color-secondary;
$info-success: green;
$info-color: #225894;

/* ================================================================
   Required
   ================================================================ */

.required-indicator {
	position:relative;
	padding-left:10px;

	&:before {
		display:block; content:'*';
		position:absolute; left:0; top:0;
		color:red;
	}
}

/* ================================================================
   Controls/Inputs
   ================================================================ */

label {
	vertical-align: middle;
}

input {
	padding: $input-padding;
	@include x-rem(font-size, 16px);
	border: $input-border-width solid $input-border-color;
	border-radius:0;
	vertical-align: middle;
	height: $input-height;
	max-width: 100%;
	width: 100%;
	transition: all 0.2s ease-in-out;

	&:hover,
	&:focus {
		border-color: $input-border-color-selected;
	}
}

input[type="checkbox"],
input[type="radio"] {
	margin-right: 5px;
	border-radius: 0;
	vertical-align: middle;
	height: auto;
}

// File inputs are very tricky cross browser so recreating height using padding
input[type="file"] {
	height: auto;
	padding-top: 0.345rem;
	padding-bottom: 0.345rem;
}

select {
	border: $input-border-width solid $input-border-color;
	vertical-align: middle;
	height: $input-height;
	padding: $input-padding;
	max-width: 100%;

	&[multiple] { height: auto; }

	&:hover,
	&:focus {
		border-color: $input-border-color-selected;
	}
}

textarea {
	padding: $input-padding;
	min-height: 100px;
	border: $input-border-width solid $input-border-color;
	border-radius:0;
	background: $input-background;
	max-width: 100%;
	transition: all 0.2s ease-in-out;

	&:hover,
	&:focus {
		border-color: $input-border-color-selected;
	}
}

button {
	vertical-align: middle;
}

/* ================================================================
   Kentico CMS
   ================================================================ */

.InfoLabel {
	color: $info-color;
}

.ErrorLabel {
	color: $error-color;
}

.EditingFormErrorLabel {
	display: block;
	color: $error-color;
	margin: $form-spacing 0 0 0;
}

.FormPanel {
	display: block;
	max-width: $bp-sw;

	table.EditingFormTable {
		width: 100%;

		thead,
		tbody,
		tr,
		td { display: block; }

		tr {
			padding: $form-spacing 0;
			border-top: 1px solid #b8b8b8;

			&:first-child { border-top: none; }
		}

		label,
		.EditingFormLabel {
			display: block;
			margin: 0 0 $form-spacing;
			font-weight: bold;
		}

		input,
		select,
		textarea {
			width: 100%;

			&[type="checkbox"],
			&[type="radio"] {
				width: auto;
			}
		}

		.ExplanationText {
			margin: $form-spacing 0 0 0;
			@include x-rem(font-size, $note-size);
		}

		.checkbox { display: inline-block; }

		// Single checkbox styling
		.single-checkbox-field {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: center;

			label { margin: 0 $form-spacing 0 0; }

			.EditingFormValueCell {
				font-size: 0;
			}

			.ExplanationText {
				@include x-rem(font-size, $note-size);
			}
		}

		// Multiple checkbox styling
		.multi-checkbox-field {
			label {
				display: inline-block;
				margin: 0;
				font-weight: normal;
			}

			input {
				display: inline-block;
				width: auto;
			}
		}

		// Radio list styling
		.radio-list-field {
			label {
				display: inline-block;
				margin: 0;
				font-weight: normal;
			}

			input {
				display: inline-block;
				width: auto;
			}
		}

		// Date picker styling
		.date-picker-field {
			input { margin: 0 0 $form-spacing 0; }

			button {
				width: 100%;
				margin: 0 0 $form-spacing 0;
			}

			.calendar-action { display: none; }

			.form-control-text {
				@extend .btn;
				width: 100%;
			}
		}

		// Captcha
		.captcha-field {
			.CaptchaTable {
				width: 100%;
				margin: 0;
				font-size: 0;
				border-collapse: collapse;

				tr {
					border: none;
					padding: 0;
				}

				input {
					@include x-rem(font-size, 16px);
					margin: $form-spacing 0;
				}
			}
		}

		// Phone
		.phone-field {
			.editing-form-control-nested-control {
				 display: flex;
				 justify-content: space-between;
				 align-items: center;
				 max-width: $bp-s;

				 span {
					 flex: 0 0 auto;
					 margin: 0 5px;
				 }

				 input {
					 min-width: 0;
					 flex: 1 1 100%;
				 }
			}
		}

		// Media selection styling
		.MediaSelectorHeader {
			input:not(:last-child) { margin-bottom: $form-spacing; }
		}

		// Calendar
		.icon-calendar + .sr-only {
			display: inline-block;
			overflow: visible;
			position: static;
			clip: auto;
			width: auto;
			height: auto;
		}
	}
}

// jQuery date picker popup
#ui-datepicker-div {
	z-index: 8999 !important;

	.datetime-ui-datepicker-prev,
	.datetime-ui-datepicker-next {
		display: none;
	}

	@at-root html[class^="js-"] & .action-buttons {
		display: none;
	}
}

@media (min-width: $bp-mw) {
	.FormPanel {
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}
}

.form {
    display: flex;
    flex-direction: column;

    &__group {
        position: relative;
        display: flex;
        align-content: center;
    }

    &__input {
        font-size: 1rem;
        font-family: inherit;
        padding: 32px 0 12px;
        width: 100%;
        display: block;
        appearance: none;
        transition: all .2s;
		background-color: transparent;
		border: transparent;
		border-bottom: 3px solid currentColor;

        &:hover, &:focus {
			outline: none;
        }
    }
    &__input:placeholder-shown + &__label{
        opacity: 0;
        visibility: hidden;
        transform: translateY(1rem);
    }
    &__input:placeholder-shown {
        padding: 22px 0;
    }
    
    &__label {
        font-size: .8rem;
        font-weight: 700;
        margin-top: 0;
        display: block;
        position:absolute;
		left: 0;
        top: 2%;
        transition: all .3s;
        color: currentColor;
    }

    &__radio-input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        width: 2rem;
        height: 2rem;
    }
    
    &__radio-label {
        cursor: pointer;
        position: relative;
        padding: 2px 16px 0 24px;
        color: $color-primary;
		font-size: 14px;
    }

    &__radio-button {
        height: 18px;
        width: 18px;
        border: 1px solid $color-primary;
		border-radius: 50%;
        display: inline-block;
        position: absolute;
        left: 0;
        top: .1rem;

        &::after {
            content: "";
            display: block;
            height: 14px;
            width: 14px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $color-primary;
			border-radius: 50%;
            opacity: 0;
            transition: opacity .2s;
        }
    }

    &__radio-input:checked ~ &__radio-label &__radio-button:after {
        opacity: 1;
    }

	@include ie() {
		input {
			padding: 20px 0 0;
		}
		label {
			display: none;
		}
	}
}

// form builder

div[id*='form-'],
.form-field {
	margin:0 0 $form-spacing;

	p {
		@media (min-width: $bp-mw) {
			@include x-rem(font-size, 20px);
		}
	}

	&.inline-form--slim {
		max-width:660px;
		margin:0 0 20px;
	}
	
	form {
	  width: 100%;
	  margin: 0 auto;
	}

	label {
		display: block;
		font-weight:bold;
		font-size: $note-size;
		margin: 0 0 5px;
		color: $color-primary;
		text-align: left;

		&.required-form-field {
			&:after {
				content:'*';
				color: $color-secondary;
			}
		}
	}
	input[type="text"],
	input[type="tel"],
	input[type="number"],
	input[type="email"],
	input[type="file"],
	input[type="password"],
	select,
	textarea {
		width: 100%;
		background:#fff;
		font-family: $font-primary;

		&[type="checkbox"],
		&[type="radio"] {
			width: auto;
		}

		&.input-validation-error {
			border:2px solid $error-color;
		}
	}
	input[type=checkbox], input[type=radio] {
		float:left;
		margin:4px 5px 5px 0;
	}
	.ktc-checkbox, .ktc-radio {
		display:flex;
		align-items: flex-start;
		position: relative;

		label {
			width:100%;
			font-weight: normal;
			cursor: pointer;
		}

		input[type=checkbox] {
			margin:0 5px;
			flex:1;
			width:20px;
			height: 20px;
			opacity: 0;

			/* Custom checkbox */
			& ~ label::before, & ~ label::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				height: 18px;
				width: 18px;
				background-color: #fff;
				border: 1px solid $color-grey-dark-2;
				cursor: pointer;
			}
			
			/* Checkmark/indicator (hidden when not checked) */
			&:checked ~ label::after {
				display: none;
			}

			&:checked ~ label::after {
				content: "";
				position: absolute;
				display: block;
				background-color: $color-grey-dark-2;
				border: none;
				width: 14px;
				height: 14px;
				top: 2px;
				left: 2px;
			}
		}
		input[type=radio] {
			margin:5px 10px 0 0;
			flex:1;
			width:20px;
			opacity: 0;

			/* Custom checkbox */
			& ~ label::before, & ~ label::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				height: 18px;
				width: 18px;
				background-color: #fff;
				border: 1px solid $color-grey-dark-2;
				border-radius: 100%;
				cursor: pointer;
			}
			/* Checkmark/indicator (hidden when not checked) */
			&:checked ~ label::after {
				display: none;
			}

			&:checked ~ label::after {
				content: "";
				position: absolute;
				display: block;
				background-color: $color-grey-dark-2;
				border: none;
				width: 14px;
				height: 14px;
				top: 2px;
				left: 2px;
			}
		}
	}
	.ktc-radio-list {
		display:block;

		.ktc-radio {

		}
	}
	.field-validation-error {
		color:$error-color;
		font-size: $note-size;
		margin:3px 0 0 0;
	}
	input[type="submit"] {
		@extend .button;
		background-color: $color-secondary;
		font-weight: bold;
		font-size: 18px;
		text-transform: uppercase;
		@include breakpoint(mw) {
			width: auto;
			margin-left: auto;
			display: block;
			padding: 0 80px;
		}
	}
	strong {
		color: $color-primary;
	}

	.form-image-list {
		list-style:none;
		margin:0 -10px;
		padding:0;
		display:flex;
		flex-wrap: wrap;
	}
	.form-image-list__item {
		width:50%;
		padding:0 10px;
		margin:0 0 20px;
	}
	.form-image-list__image {
		background-size: cover;
		background-position: 50% 50%;
		margin:0 0 5px;

		&:before {
			content:'';
			padding-bottom:100%;
			display:block;
		}
	}
	.form-image-list__name {
		font-weight:600;
	}
	@include breakpoint(sw) {
		.form-image-list__item {
			width:33.33%;
		}
	}
	@include breakpoint(m) {
		.form-image-list__item {
			width:25%;
		}
	}
	@include breakpoint(mw) {
		.form-image-list__item {
			width:20%;
		}
	}
	@include breakpoint(l) {
		.form-image-list__item {
			width:16.66%;
		}
	}
}
.form-content, .ktc-default-section {
	text-align: left;
}
.col-form-make-offer {
	.editing-form-control-nested-control input {
		position: relative;
		&+div::before {
			content: '$';
			display: block;
			position: absolute;
			top: 38px;
			left: 28px;
			font-weight: $fontWeight-semibold;
			color: $color-primary;
			font-size: 18px;
		}
	}
	input{
		padding-left: 30px;
		@include breakpoint(mw) {
			max-width: 300px;
		}
	}		
}

.form-thumbnails__grid {
	display: flex;
	flex-wrap: wrap;
}
.form-thumbnails__item {
	width: 50%;
	margin-bottom: 10px;

	@include breakpoint(mw) {
		width: 33.33%;
	}
	@include breakpoint(l) {
		width: 16.66%;
	}

	.ktc-checkbox {
		.form-control {
			position: absolute;
			bottom: 5px;
			left: 0;
		}
		label {
			&::before {
				top: inherit !important;
				bottom: 5px;
			}
			&::after {				
				top: inherit !important;
				bottom: 7px;
				display: none;
			}
			.form-thumbnails__name {
				padding: 0 0 0 25px;
				margin: 10px 0 0;
				display: block;
			}
			img {
				border: 1px solid $color-grey-dark-2;
			}
		}
	}
}
.form-thumbnails__item-inner {
	padding: 0 10px;
}
.form-thumbnails__disclaimer {
	font-size: 1rem;
}
.trainingClassForm {
	p {
		font-size: 1rem;
		margin-bottom: 5px;
	}
	strong {
		color: $color-primary;
	}
	.text-right {
		text-align: left;
		@include breakpoint(mw) {text-align: right;}
	}
	h3 {
		margin-bottom: 10px;
	}
	.form-section {
		margin-bottom: 30px;
	}
	.btn {
		text-transform: uppercase;
		font-weight: $fontWeight-bold;
	}
}
.trainingClassForm__submit {
	text-align: right;
}
.trainingClassForm__pagination {
	display: flex;
	justify-content: center;

	button {
		margin-right: 10px;
		border: none;
		background-color: transparent;
		font-family: $font-primary;
		color: $color-primary;
		cursor: pointer;

		&.active {
			color: $color-secondary;
			font-weight: $fontWeight-bold;
		}
		&[disabled] {
			cursor: default;
		}
	}
}
html{
scroll-behavior: smooth;
}