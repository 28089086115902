.site-header {
    position: relative;
    padding: 0;
    background:#fff;
}
.header-utility-container {
    background-color: $color-primary;
}
.header-col {
    position: static;
}

.header-top {
    display: flex; 
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 0;
    @include breakpoint(mw) {
        position: relative;
        padding: 25px 0;
    }
}

.header-logo {
    margin: 0 20px;
    font-size: 0;
    width: 144px;

    @include breakpoint(mw) {
        width: 232px;
    }

    &.alt-logo {
        width: 220px;

        @include breakpoint(mw) {
            width: 250px;
        }
    }

    a {
        display: block;
    }
}

.culture-selector {
    position: relative;
    margin:0 20px 0 0;
}
.culture-selector__button {
    @extend .button !optional;
    display:flex;
    align-items: center;

    &:after {
        content:'';
        border-top:5px solid $color-invert;
        border-left:4px solid transparent;
        border-right:4px solid transparent;
        margin:0 0 0 7px;
    }
}
.culture-selector__list {
    @include resetList;
    position: absolute;
    top:100%;
    left:0;
    border:1px solid $color-primary;
    background:#fff;
    z-index: 1001;
    display:none;
}
.culture-selector__item {

}
.culture-selector__link {
    text-decoration: none;
    display: block;
    padding:7px 15px;

    &:hover,
    &:focus {
        background:$color-primary;
        color: $color-invert;
        text-decoration: none;
    }
}

.header-search-trigger {
    @include resetBtn;
    display: block;
    width: 20px;
    height: 20px;
    font-size: 0;
    color: $color-primary;
    flex: 0 0 auto;
    @include breakpoint(mw) {
        width: 30px;
        height: 30px;
    }

    &:hover,
    &:focus {
        color: $color-secondary;
    }

    svg {
        width: 100%;
        height: 100%;
        fill: currentColor;
    }
}

.header-search {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 101;
    background: $color-primary;
    
    @include breakpoint(mw) {
        top: 0;
        right: -15px; 
        left: inherit;
   }
}

.header-search-input {
    display: block;
    width: 100%;
    height: 40px;
    background: none;
    padding: 10px $grid-gutter-em;
    color: $color-invert;

    @include placeholder {
      color: $color-invert;
      opacity: 1;
    }
}

.header-search-submit {
    @include resetBtn;
    position: absolute;
    top: 50%;
    right: $grid-gutter-em;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    color: $color-invert;

    &:hover,
    &:focus {
        opacity: 0.75;
    }

    svg {
        width: 100%;
        height: 100%;
        fill: currentColor;
    }
}

.header-bottom {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 100;
    border-top: 5px solid $color-secondary;
    border-bottom: 5px solid $color-secondary;
    
    @include breakpoint(mw) {
        border: none;
        margin-left: 3rem;
   }
}

@media (min-width: $bp-mw) {
    .header-logo {
        margin: 0 20px 0 0;
        flex: 1 0 auto;
    }

    .header-search-trigger {
        display: none;

        &--desktop {
            display: block;
            color: $color-invert;
            padding: 5px;
            margin-left: 1rem;
            z-index: 1;
        }
    }

    .header-search,
    .header-search[data-expander-target] {
        position: absolute;
        max-width: 450px;
    }

    .header-bottom,
    .header-bottom[data-expander-target] {
        position: static;
        display: block;
    }
}
.header-utility {
    @include breakpoint(mw) {
        display: none;
    }
    &--desktop {
        display: none;
        @include breakpoint(mw) {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: relative;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        background: #fff;
        padding: 1rem 0;
        border-top: 3px solid $color-grey-dark-2;
        
        @include breakpoint(mw) {
            border: none;
            flex-direction: row;
            align-items: center;
            position: relative;
            height: 35px;
            padding: 0;
            right: 0;
            background-color: $color-primary;
        }

        li {
            a {
                display: block;
                padding: .3rem 1rem;

                &:hover,
                &:focus {
                    color: $color-secondary;
                    text-decoration: none;
                }

                @include breakpoint(mw) {
                    padding: .3rem 2rem;
                    color: $color-invert;
                }
            }
        }
    }
}