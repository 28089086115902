.wholesale {
    margin: 0 auto $grid-row-gap;
    &-box {
        @include breakpoint(mw) {
            display: flex;
            grid-gap: 30px;
        }
    }
    &__description-box {
        @include breakpoint(mw) {
            width: 35%;
            @include ie() {
                width: 40%;
            }
            p {
                margin-bottom: 30px;
            }
        }
    }
    &__icons-box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin: $grid-row-gap auto 0;
        grid-gap: 30px;
        @include breakpoint(mw) {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            width: 65%;
            margin: $grid-gutter;
            @include ie() {
                display: flex;
                flex-wrap: wrap;
                .icon-cta {
                    flex: none;
                    width: 25%;
                    margin-top: 12px;
                }
            }
        }
        
    }
    .btn--link {
        margin-left: auto;
        @include breakpoint(mw) {
            margin-left: 0;
        }
    }
    .heading-line {
        @include breakpoint(mw) {
            @include x-rem(font-size, 40px);
        }
    }
}
.icon-cta {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: all .2s;
    &__image {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 60px;
        width: 100%;
    }
    &__title {
        color: $color-secondary;
        font-weight: $fontWeight-bold;
        line-height: 1;
        margin-top: 10px;
        font-size: 14px;
        @include breakpoint(mw) {
            width: 100%;
        }
    }
        
    &:hover, &:active {
        text-decoration: none;
        transform: scale(110%);
    }
}