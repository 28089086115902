/* ================================================================
   Print Styles
   ================================================================ */

   .header-logo-print,
   .logo-footer-print {
	   display:none;
   }

@media print {
	* { background-color: transparent !important; color: #000 !important; box-shadow:none !important; text-shadow: none !important; -webkit-print-color-adjust: exact; print-color-adjust: exact;}
	a, a:visited { text-decoration: underline; }
	// a[href]:after { content: " (" attr(href) ")"; }
	abbr[title]:after { content: " (" attr(title) ")"; }
	.ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after { content: ""; }
	pre, blockquote { border: 1px solid #999; page-break-inside: avoid; }
	thead { display: table-header-group; }
	tr, img { page-break-inside: avoid; }
	img { max-width: 100% !important; }
	@page { margin: 0.5cm; }
	p, h2, h3 { orphans: 3; widows: 3; }
	h2, h3 { page-break-after: avoid; } 

	/* print logo swap */

	.header-logo,
	.logo-footer {
		display:none;
	}
	.header-logo-print,
	.logo-footer-print {
		display:block;
		page-break-inside: avoid;
		break-inside:avoid;
		position: relative;
		width: 100%;

		img {
			display:block;
			page-break-before: auto;
			break-inside:avoid;
			position: relative;
			width: 100%;
		}
	}
}