.home-bottom {
    margin: 3rem auto;
}
.home-bottom-box {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-gap: 3rem;

    @include breakpoint(mw) {
        display:-ms-grid;
        -ms-grid-columns:1fr 1fr 1fr;
        -ms-grid-rows:1fr 1fr 1fr;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: $grid-gutter;
        grid-template-areas:"about about news"
                            "about about news"
                            "newsletter newsletter news";
        .about {
            -ms-grid-row:1; 
            -ms-grid-row-span:2;
            -ms-grid-column:1; 
            -ms-grid-column-span:2;
            grid-area: about;
        }
        .newsletter {
            -ms-grid-row:3; 
            -ms-grid-column-span:2;
            grid-area: newsletter;
        }
        .news {
            -ms-grid-row:1; 
            -ms-grid-column:3;
            -ms-grid-row-span:3;
            grid-area: news;
        }
    }

}

.about, .newsletter, .news {
    margin: 0 (-$grid-gutter);
    @include breakpoint(mw) {
        margin: auto;
    }
}