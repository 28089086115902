/*================================================================
            MAIN NAVIGATION STYLES
================================================================*/

.main-navigation {
    position: static;

    //Nav Defaults

    ul {
        list-style: none;
        padding:0;
        margin:0;
    }
    li {

    }
    a,
    button {
        @extend .toggle-plus;

        &:hover,
        &:focus {
            text-decoration: none;
            color: $color-secondary;
        }
        &.active {
            color: $color-secondary;  
        }

        &[data-expander-toggle] {
            position: relative;
        }
    }

    .main-navigation__heading {
        font-weight:bold;
    }
    
    .mega-content {
        display: none;
    }

    // 1st Level
    // ********************************************* //
    > ul,
    .main-navigation-mega > ul {
        background:#fff;

        > li {

            > a,
            > button {
                text-transform: uppercase;
                font-weight: $fontWeight-bold;
                font-family: $font-primary;
                padding: 10px 0;
                margin: 0 10px;
                @include breakpoint(mw) {
                    margin: 0;
                    padding: 0;
                }

                &:hover,
                &:focus {
                    
                }
            }
                // 1st Level
                // ********************************************* //
            .main-navigation-mega > ul {
                margin: 0 10px;
                li > a, 
                button {
                    
                }
                
                // 1st Level
                // ********************************************* //
                li > ul {
                    > li {
                        a, button {
                            display: block;
                            padding: 8px 10px;
                            text-transform: uppercase;
                            font-family: $font-primary;
                            margin: 0;
                            text-align: left;
                            width: 100%;
                            color: $color-primary;
                            line-height: 1.2;
                            &:hover,
                            &:focus, 
                            &.active {
                            }
                        }
                    }
                    // 2nd Level
                    // ********************************************* //
                    li > ul {
                        > li {
                            a, button {
                                text-transform: capitalize;
                                font-weight: normal;
                                padding: 8px 10px;
                                background-color: #fff;
                                color: $color-primary;
                                margin: 0;
                                &:hover,
                                &:focus, 
                                &.active {
                                    background-color: #fff;
                                }
                            }
                        }
                    }
                }
            }

            // 2nd Level
            // ********************************************* //
            > ul {
                background:#fff;

                > li {

                    > a,
                    > button {
                        @extend .toggle-chevron;
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: .9rem;
                        padding: 10px 15px;
                        margin: 0 10px;
                        width: auto;
                        @include breakpoint(mw) {
                            margin: 0;
                        }
                
                        &[data-expander-toggle] {
                            position: relative;
                        }

                        &:hover,
                        &:focus {
                            color: $color-primary;
                        }
                        &.active {
                            background-color: $color-secondary;
                            color: $color-invert;
                        }
                    }
        
                    // 3rd Level
                    // ********************************************* //
                    
                    > ul {
                        background:#fff;
    
                        > li {
    
                            > a,
                            > button {
                                text-transform: none;
                                font-weight: $fontWeight-medium;
                                font-size: .9rem;
                                padding: 10px 20px;
                                margin: 0 10px;
                                width: auto;
                                @include breakpoint(mw) {
                                    margin: 0;
                                }
    
                                &:hover,
                                &:focus {
                                    text-decoration: none;
                                    color: $color-primary;
                                }
                            }       
                            
                            // 4th Level
                            // ********************************************* //
                            
                            > ul {
                                background:#fff;
            
                                > li {
            
                                    > a,
                                    > button {
                                        font-size: .9rem;
                                        padding: 10px 25px;
                                        margin: 0 10px;
                                        width: auto;
                                        @include breakpoint(mw) {
                                            margin: 0;
                                        }
            
                                        &:hover,
                                        &:focus {
                                            text-decoration: none;
                                            color: $color-secondary;
                                        }
                                    }                                
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: $bp-mw) {


        // 1st Level
        // ********************************************* //
        > ul {
            display:flex;
            justify-content: space-between;
            position: static;
            background:none;

            > li {
                position: static;

                &[data-type="mega"] {
                    position: static;
                }

                > a,
                > button {
                    text-align: center;
                    background:none;
                    color:#333333;

                    &:hover,
                    &:focus {

                    }

                    &[data-expander-toggle] {
                        position: relative;
            
                        // Arrows
                        &:after {
                            border-color: #333 transparent transparent transparent;
                        }
            
                        &.active {
                            &:after {
                            }
                        }
                    }
                }

                // 2nd Level
                // ********************************************* //
                > ul {
                    position:absolute;
                    width:auto;
                    top:100%;
                    box-shadow: 0 8px 10px rgba(0,0,0,.3);
                    border-bottom: 5px solid $color-secondary;
                    z-index: 1;

                    > li {

                        > a,
                        > button {

                            &:hover,
                            &:focus {
                                color: $color-secondary;
                            }
                            &.active {
                                color: #fff;
                            }
                        }
            
                        // 3rd Level
                        // ********************************************* //
                        
                        > ul {
        
                            > li {
        
                                > a,
                                > button {
        
                                    &:hover,
                                    &:focus {
                                        text-decoration: none;
                                    }
                                }       
                                
                                // 4th Level
                                // ********************************************* //
                                
                                > ul {
                
                                    > li {
                
                                        > a,
                                        > button {
                
                                            &:hover,
                                            &:focus {
                                                text-decoration: none;
                                            }
                                        }                                
                                    }
                                }
                            }
                        }
                    }
                }
            }
            // Mega
            // ********************************************* //
            [data-nav-type="mega"] {
                position: static;

                .mega-content {
                    display: block;
                    margin: 0;
                    padding-right: 30px;
                    .btn--link {
                        margin-top: 1rem;
                        text-align: left;
                    }
                }
                .main-navigation-mega {
                    padding:20px 0;
                    position: absolute;
                    top:100%;
                    left:0;
                    width:100%;
                    background-color: #fff;
                    z-index: 1;

                    &::before {
                        content: '';
                        height: 100%;
                        width: 100vw;
                        left: calc((-100vw + 100%)/2);
                        top: 0;
                        position: absolute;
                        background-color: #fff;
                        border-bottom: 5px solid $color-secondary;
                        z-index: -1;
                    }

                    ul {
                        display:block;
                        animation: none;
                        background:none;
                        margin: 0;
                    }

                    > ul {
                        display:flex;

                        // Mega 1st Level
                        // ********************************************* //
                        > li {
                            flex:1;

                            > a,
                            > button {
                                background:red;

                                &:hover,
                                &:focus {
                                    opacity: .7;
                                    
                                }
                            }       

                            // Mega 2nd Level
                            // ********************************************* //
                            > ul {
                                
                                > li {
            
                                    > a,
                                    > button {
                                        &::after {
                                            //display: none !important;
                                        }

                                        &:hover,
                                        &:focus {
                                            background: $color-secondary;
                                            color: $color-invert;
                                            &[data-expander] {
                                                &:after {
                                                    border-color: #fff;
                                                }
                                            }
                                        }

                                        &[data-expander] {

                                            &:after {
                                                content: '';
                                                display: inline-block;
                                                border-right: 4px solid $color-secondary;
                                                border-bottom: 4px solid $color-secondary;
                                                width: 10px; height: 10px;
                                                transform:  translateY(0px) translateX(8px) rotate(-45deg);
                                                background-color: transparent;
                                                cursor:pointer;
                                            }
                                    
                                            &.active:after {
                                                border-color: #fff;
                                            }
                                        }
                                    }     
                                    
                                    // Mega 3rd Level
                                    // ********************************************* //
                                    > ul {
                                        display: none;
                                        > li {
                    
                                            > a,
                                            > button {
                                                background:#fff;
                                                text-transform: capitalize;

                                                &:hover,
                                                &:focus {
                                                    color: $color-secondary;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    
                }
            }
        }
    }

    .third-lvl-desktop.active ul {
        @include breakpoint(mw) {
            display: block !important;
        }
    }
    .third-lvl-desktop {
        li > a {
        }

    }
}