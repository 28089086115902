.prod-toolbox {
    margin: $grid-row-gap auto;

    &__container {
        display: flex;
        align-items: center;
        flex-direction: column;
        @include breakpoint(mw) {
            flex-direction: row;
        }
    }

    &__heading {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        h3 {
            margin: 0;
        }
        @include breakpoint(mw) {
            margin-right: $grid-gutter;
        }
    }

    &__toggle {
        @extend .toggle-plus;
        position: relative;
        border: 3px solid $color-secondary;
        border-radius: 100%;
        height: 2rem;
        width: 2rem;
        padding: 0 !important;
        margin-right: 1rem;
        flex-shrink: 0;
        &:after,
        &:before {
            margin: auto;
            right: 1rem !important;
            top: 4px !important;
            bottom: 0;
        }

        @include breakpoint(mw) {
            display: none;
        }
    }

    &__content {
        width: 100%;
        display: none;
        @include breakpoint(mw) {
            display: block;
        }
        .icon-cta__title {
            font-size: 14px;
            @include breakpoint(mw) {
                font-size: 10px;
            }
        }
        .icon-cta__image {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            height: 50px;
            width: 100%;
        }
    }

    &__inner {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: $grid-gutter;
        @include breakpoint(mw) {
            display: flex;
            flex: auto;
        }
    }
}
