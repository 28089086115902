.slider-product-single {
    &__nav-box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0 0;
    }
    &__nav {
        width: 80%;
        position: relative;
    }
    &__image {
        background-size: cover;
        background-position: center center;
        .aspect-ratio {
            padding-top: 66.66%; /* 3:2 Aspect Ratio (divide 2 by 3 = 0.6666)  */
        }
    }
    &__thumb {
        margin: auto 5px;
        border: 2px solid transparent;
        cursor: pointer;
        background-size: cover;
        background-position: center center;
        &.slick-current {
            border: 2px solid $color-secondary;
        }
        .aspect-ratio {
            padding-top: 66.66%; /* 3:2 Aspect Ratio (divide 2 by 3 = 0.6666)  */
        }
        &--video {
            @extend .btn--play;
            padding: 0;
            &::before {
                right: 0;
            }
            &::after {
                left: 0;
                right: -3px;
            }
        }
        
    }
    &__btn--prev, &__btn--next {
        margin: 0;
        position: relative !important;
        left: 0 !important;

        svg {
            fill: $color-secondary;
            width: 15px !important;
        }
    }
}