/* ================================================================
   Alerts
   ================================================================ */

.site-alert-container {
	background:$color-secondary;
	h1, h2, h3, h4, h5, h6, p {
		margin: 0;
		padding: 0;
	}
}
.site-alert {
	display:flex;
	flex-direction: column;
	align-items: center;
	grid-gap: $grid-row-gap;
	padding: $grid-row-gap;

	@include breakpoint(mw) {
		padding: 0;
		flex-direction: row;
	}

	.btn-more {
		background-color: transparent;
		border: 2px solid currentColor;
		font-weight: $fontWeight-semibold;
		text-transform: uppercase;
		padding: .5rem 3rem;
		line-height: 1;
		@include breakpoint(mw) {
			padding: .5rem;
			margin-left: auto;
		}
		&:hover {
			opacity: .7;
		}
	}
}
.site-alert__image {
	max-width:84px;
	padding:0 20px 0 0;
}
.site-alert__description {
	color:$color-invert;
	font-size: 14px;

	a {
		text-decoration: underline;
		color:$color-invert;
		font-weight:bold;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}
}
.site-alert__close {
	background: transparent;
	border: none;
	color: #fff;
	position: absolute;
	top: 0;
	left: 10px;
	width: 50px;
	height: 50px;
	cursor: pointer;

	&:hover {
		opacity: .8;
	}
	&:before, &:after {
		top: 0;
		bottom: 0;
		margin: auto;
		position: absolute;
		content: '';
		height: 26px;
		width: 4px;
		background-color: #fff;
	}
	&:before {
		transform: rotate(45deg);
	}
	&:after {
		transform: rotate(-45deg);
	}

	@include breakpoint(mw) {
		left: 0;
		position: relative;
	}
}