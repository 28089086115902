.product-single {
    &__meta-info {
        color: $color-primary;

        @include breakpoint(mw) {
            max-width: 70%;
        }
    }
    &__price {
        font-size: 23px;
        font-weight: $fontWeight-bold;
        margin-top: 8px;
    }
    &__ctas {
        margin: $grid-gutter 0;
        display: flex;
        flex-direction: column;
        .btn {
            font-weight: $fontWeight-bold;
            margin: 0;


            &--offer {
                flex: 1 100%;
                text-transform: uppercase;
                background-color: $color-secondary;
                font-size: 22px;
                margin-right: 0 !important;
                margin-bottom: 8px;

                &:hover, &:active {
                    opacity: .8;
                }
            }
        }
        .btn-group {
            display: flex;
            .btn {
                width: 50%;
                &:last-child {
                    margin-left: $grid-gutter;
                }
                &:first-child {
                    margin-left:0;
                }
            }
        }
        .download-form-btn {
            margin-top: 8px;
        }
    }
    &__share {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include breakpoint(mw) {
            flex-direction: column;
        }
    }
    &__share-btns {
        display: flex;
        width: 50%;
        margin: $grid-gutter 0;
        @include breakpoint(mw) {
           width: 100%;
        }
        .product-single__share-btn {
            &:first-child {
                display: none;
                @include breakpoint(mw) {
                    display: block;
                }
                svg {
                    margin-left: 0;
                }
            }
        }
    }
    &__share-social {
        width: 50%;
        display: flex;
        justify-content: space-evenly;
        @include breakpoint(mw) {
           width: 100%;
        }
        a {
            display: flex;
            svg {
                fill: $color-grey-dark-2;
                margin: 0;
                width: 45px;
                height: 45px;
                &:hover {
                    fill: $color-secondary;
                }
            }
        }
    }
}
.product-single__share-btn {
    border: none;
    background-color: transparent;
    cursor: pointer;

    svg {
        fill: $color-grey-dark-2;
    }
}
.product-group-description {
    margin-top: 2rem;
    @include breakpoint(mw) {
        margin-top: 0;
    }
}
.product-links {
    margin: 0 ;
    ul {
        @include resetList;

        li {
            margin: 8px 0;

            a {
                color: $color-secondary;
                text-decoration: underline;
                font-weight: $fontWeight-bold;
            }
        }
    }
}
.product__content {
    margin-bottom: $grid-gutter;
}
.social__icon {
    fill: $color-grey-dark-2;
    width: 40px;
    height: 40px;
    margin-left: $grid-gutter;
    &:hover {
        fill: $color-secondary;
    }
}
.product-from__heading {
    text-align: left;
    margin-bottom: 10px;
}
.product-from__name {
    text-align: left;
    color: $color-secondary;
    margin-bottom: 0;
}

button.modaal-close {
    position: absolute;
    right: 0;
    top: 0;
    &::before, &::after {
        background-color: $color-secondary;
        &:hover, &:active, &:focus {
            opacity: .8;
        }
    }
}

/* print styles */

.product-mid-logo {
    display:none;
}
@media print {

    .site-alert-container,
    .hamburger,
    .header-bottom,
    .header-search-trigger,
    .search-landing,
    .blog-listing__thumb-icon,
    .product-links,
    .product-single__ctas,
    .product-single__share,
    .equipment-used,
    .slider-product-list__nav-box,
    .faqs-tabs-accordion-toggle:before,
    .faqs-tabs-accordion-toggle:after,
    .promo,
    .about,
    .newsletter,
    .footer__btns,
    .footer__social,
    .footer__nav,
    .back-to-top,
    .footer__bottom,
    .breadcrumbs,
    .header-search-input,
    .gallery,
    .header-utility-container {
        display: none !important;
    }
    .faqs-tabs-accordion-target {
        display: block;
    }
    .header-logo {
        width: 400px;
        margin: auto;
    }
    .slider-product-list__main {
        .slick-list {
            overflow: auto;
        }
        .slick-track {
            opacity: 1;
            width: 100% !important;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: start;
        }
        .slick-slide {
            display: none !important;
        }
        .slick-slide:nth-child(1), 
        .slick-slide:nth-child(2), 
        .slick-slide:nth-child(3), 
        .slick-slide:nth-child(4), 
        .slick-slide:nth-child(5), 
        .slick-slide:nth-child(6) {
            display: block !important;
            opacity: 1 !important;
            position: static !important;
            width: 30% !important;
            margin: 0 6px 12px;
        }
        /* one item */
        .slick-slide:only-child {
            width: 50% !important;
        }

        /* two items */
        .slick-slide:first-child:nth-last-child(2),
        .slick-slide:first-child:nth-last-child(2) ~ .slick-slide {
            width: 48% !important;
        }
    }

    .product-single__meta-info {
        display: flex;
        align-items: center;
    }
    .product-single__price {
        margin: 0 0 0 10px;
    }

    // always start specs tabs on a new page on printing
    .product-mid-logo {
        display:none;
        page-break-before:always;
        break-before: always;
        width: 100%;

        img {
            width: 100%;
        }
    }
    
    // avoid blank space on printing
    .interior-content-grid {
        display: table;
        width: 100% !important;
    }
    .footer-top {
      margin-top: 0;
    }
    .footer__top-inner {
      padding: 0;
    }
    .header-top, .header-col {
        padding: 0;
    }
    .interior-left {
        margin-bottom: 0;
    }
    .interior-rail-full h1 {
        font-size: pxtorem(18px);
        margin-bottom: 5px;
    }
    .interior-rail-full h3 {
        font-size: pxtorem(14px);
        margin-bottom: 10px;
    }
    .product-single__meta-info, .product-single__price {
        font-size: pxtorem(14px);

    }
    .faqs-tabs-accordion {
        margin-bottom: 5px;
    }
    .faqs-tabs-accordion-toggle {
        padding: 5px 0;
        font-size: pxtorem(15px);
    }
    .faqs-tabs-accordion-target {
        padding: 10px;
        font-size: pxtorem(14px);
    }
    .mb40 {
        margin-bottom: 0 !important;
    }
    footer {
        margin-top: 10px;
    }
	.header-logo-print {
		margin-top: -35px;
	}
    .logo-footer-print {
        border-top: 5px solid $color-secondary;
        position: fixed !important;
        bottom: 0;
        left: 0;
    }
    .footer__top {
        border: none;
    }
}

/******************************** 
** Fix Image Orientation - EXIF 
********************************/
.fix-orientation--3 {
    image-orientation: none;
    transform: rotate(180deg);
}
.fix-orientation--6 {
    image-orientation: none;
    transform: rotate(90deg);
}
.fix-orientation--8 {
    image-orientation: none;
    transform: rotate(-90deg);
}