.equipment-new {
    margin: $grid-row-gap auto;
    &__container {
        @include breakpoint(mw) {
            display: flex;
            align-items: center;
            position: relative;
        }
    }

    &__content {
        @include breakpoint(mw) {
            width: 40%;
            max-width: 375px;
            padding-right: 16px;
        }

        a.btn--link {
            margin-bottom: 2rem;
            @include breakpoint(mw) {
                margin-left: 0;
            }
        }
    }
    &__slider-box {
        @include breakpoint(mw) {
            width: 100%;
            max-width: 755px;
        }
    }

    .btn--link {
        margin-left: auto;
    }
}
.equipment-new__slider {

}
.equipment-new-item {
    color: #fff;
    
    &:not(:last-child) {
        margin-right: $grid-gutter;
    }

    @include breakpoint(mw) {
        display: flex !important;
        &:not(:last-child) {
            margin-right: 0;
        }
    }
    &:hover {
        color: #fff;
    }

    &__image {
        margin: 0;
        position: relative;
        background-size: cover;
        padding-top: 75%;

        @include breakpoint(mw) {
            flex: 2 1 55%;
            height: 376px;
            padding-top: 0;
        }


        &::before, &::after {
            content: '';
            display: block;
            width: 30%;
            height: 100%;
            position: absolute;
            top:0;
            right: 0;
            mix-blend-mode: multiply;
            background-color: $color-secondary;
            clip-path: polygon(100% 0, 100% 100%, 0 100%);
            z-index: 1;
            @include ie() {
                display: none;
            }
        }
        &::after {
            display: none;
            @include breakpoint(mw) {
                display: block;
                mix-blend-mode: normal;
                background-color: $color-grey-dark-2;
                clip-path: polygon(0 0, 100% 0, 100% 100%);
                z-index: 2;
                right: -1px;
                @include ie() {
                    display: none;
                }
            }
        }
    }
    &__content {
        background-color: $color-grey-dark-2;
        color: $color-invert;
        padding: 1rem 1rem 2rem;
        z-index: 3;

        @include breakpoint(mw) {
            flex: 2 1 45%;
            position: relative;
        }
    }

    &__cat {
        margin-bottom: 10px;
        @include breakpoint(mw) {
            position: relative;
            margin-left: -2rem;
            padding-right: 3rem;
            z-index: 1;
            @include ie() {
                margin-left: 0;
            }
        }
    }

    &__name {
        font-weight: $fontWeight-bold;
        @include breakpoint(mw) {
            position: relative;
            margin-left: -2rem;
            padding-right: 3rem;
            z-index: 1;
            font-size: 23px;
            @include ie() {
                margin-left: 0;
            }
        }
    }
}
.equipment-new__slider--typeA {
    @include breakpoint(mw) {
        .equipment-new-item__image {
            flex-basis: 60%;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
        .equipment-new-item__content {
            flex-basis: 40%;
        }
        .equipment-new-item__image::before, 
        .equipment-new-item__image::after {
            width: 70px;
        }
    }
}

.equipment-new__slider-controls {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    @include breakpoint(mw) {
        position: absolute;
        right: 3rem;
        bottom: 0.5rem;
    }
}
.equipment-new__slider-btn {
    @extend %slider-btn;
    color: $color-grey-dark-2;

    @include breakpoint(mw) {
        color: $color-invert
    }
    &--prev {
        @extend %slider-btn--prev;
        display: none;
        @include breakpoint(mw) {
            display: inherit;
            order: -2;
        }
    }
    &--next {
        @extend %slider-btn--next;
        display: none;
        @include breakpoint(mw) {
            display: inherit;
        }
    }
    &--pause {
        @extend %slider-btn--pause;
        margin-left: 1rem
    }
    &--dots {
        @extend %slider-btn--dots;
        order: -1;
    }
}