.faqs {
    margin-top: $grid-row-gap;
    @include breakpoint(mw) {
        display: flex;
        grid-gap: $grid-gutter;
    }
}
.faqs-tabs {
    color: $color-primary;
    @include breakpoint(mw) {
        flex: 1;
        @include ie() {
            margin-right: $grid-gutter;
        }
    }
}
.faqs-tabs-accordion {
    margin-bottom: $grid-gutter;
    &:last-child {
        @include breakpoint(mw) {
            margin-bottom: 0;
        }
    }
}
.faqs-tabs-accordion-toggle {
    @extend .toggle-plus;
    background-color: $color-grey-light-3;
    color: $color-primary;
    padding: 8px $grid-gutter;
    font-size: 18px;
    font-weight: $fontWeight-bold;
    font-family: $font-primary;
    width: 100%;
    text-align: left;
}
.faqs-tabs-accordion-target {
    padding: 20px $grid-gutter;
    border: 2px solid $color-grey-light-3;
}

.faqs-image {
    background-size: cover;
    background-position: center center;
    width: 100%;
    @include breakpoint(mw) {
        flex: 1;
    }
    .aspect-ratio {
        padding-top: 66.66%;
        @include breakpoint(mw) {
            padding-top: 0;
        }
    }
}