.search-landing {
    background-color: $color-grey-light-3;
    margin-bottom: $grid-row-gap;
}

.search-catalog {
    padding: $grid-gutter 0;
    @include breakpoint(mw) {
        padding: 2rem 0;
    }
}

.search-catalog__upper {
    @include breakpoint(mw) {
        display: flex;
    }
}

.search-catalog__lower {
    display: flex;
    justify-content: space-between;
    margin-top: $grid-gutter;
    font-weight: $fontWeight-bold;
    text-transform: uppercase;
    position: relative;
    color: $color-primary;
    font-family: $font-primary;
    
    @include breakpoint(mw) {
        justify-content: flex-start;
        margin: 0;
    }
}
.search-catalog__categories {
    position: static;
    @include breakpoint(mw) {
        margin-left: 3rem;
    }
    &-toggle {
        font-family: $font-primary;
        font-weight: $fontWeight-bold;
        text-transform: uppercase;
        color: $color-primary;
        @extend .toggle-plus;
        &:hover, &:active, &:focus {
            text-decoration: underline;
        }
    }
    
    ul {
        @include resetList;
        position: absolute;
        background-color: #fff;
        border-bottom: 3px solid $color-secondary;
        padding-left: 1rem;
        z-index: 2;
        box-shadow: 0 8px 10px rgba(0,0,0, .3);
        width: 100%;
        top: 38px;
        left: 0;
        @include breakpoint(mw) {
            width: auto;
            top: 50px;
            left: inherit;
            padding-right: 6rem;
        }

        li a {
            display: block;
            padding: 6px 0;
            width: 100%;

            &:hover, &:focus {
                color: $color-secondary;
                text-decoration: none;
            }
        }
    }
}

.search-catalog__form {
    flex-direction: row;
    position: relative;
    flex-wrap: wrap;
    @include breakpoint(mw) {
        flex: 1;
        margin-left: 2rem;
    }
    .hawk-autosuggest-menu {
        position: absolute;
        width: 100%;
        top: 55px;

        h3 {
            margin-bottom: 10px;
        }

        ul {
            @include resetList;
            padding: 10px;
            li, div {
                cursor: pointer;
                &:hover, &:focus {
                    opacity: .8;
                }
            }
        }
    }
    .search-input-box {
        flex: 1;
        input.form__input {    
            color: $color-primary;
            border: none;
            border-bottom: 1px solid #42444C;
            @include placeholder {
                color: $color-primary;
            }
        }
        @include ie() {
            input {
                padding: 20px 0 0;
            }
            label {
                display: none;
            }
        }
    }
    .search-button-box {
        flex: 0;
        margin-left: $grid-gutter;
        @include ie() {
            flex: none
        }
    }
}
.search-catalog__options {
    display: block;
    margin-top: $grid-gutter;
    flex-basis: 100%;
    @include breakpoint(mw) {
        display: flex;
        justify-content: space-between;
    }

    .form__radio-group {
        margin-bottom: 10px;
        @include breakpoint(mw) {
            margin-bottom: 0;
        }
    }
}