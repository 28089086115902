/* ================================================================

Pagination

   ================================================================ */

   .pagination {
   
      &.pagination-top {
         border-top:0;
         border-bottom:1px solid #999999;
         padding:0 0 10px;
         margin:0 0 20px;
      }
   }
   
   .pagination__results {
      @include x-rem(font-size, 14px);
   }
   
   .pagination__list {
      @include resetList;
      display:flex;
      justify-content: space-between;
   }
   
   .pagination__list-item {
   
   }
   
   .pagination__link {
      padding:0;
      display:block;
      position:relative;
      color:$color-primary;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: $fontWeight-bold;
      font-family: $font-primary;
      cursor: pointer;
      background-color: transparent;
      border: none;

      @include breakpoint(mw) {
         font-size: 20px;
      }

      &--prev {
         &::before {
            margin-right: .5rem;
            @extend %arrow, %arrow--left;
            @include breakpoint(mw) {
               padding: 5px;
            }
         }
      }
      &--next {
         &::after {
            margin-left: .5rem;
            @extend %arrow, %arrow--right;
            @include breakpoint(mw) {
               padding: 5px;
            }
         }
      }
      &--disabled {
         opacity: .4 !important;
         cursor: auto;
      }
   
   
      &:hover,
      &:focus {
         opacity: .7;
         text-decoration: none;
      }
   }
   
   .pagination__link--active {
      color:$color-secondary;
   
      svg {
         fill:$color-primary;
      }
   }

   .pagination__pages {
      @include resetList;
      display: flex;
      grid-gap: $grid-gutter;
      align-items: center;
      font-weight: bold;
      @include breakpoint(mw) {
         font-size: 20px;
      }
      form {
         width: 100px;
         color: $color-grey-dark-2;
         input {
            font-family: $font-primary;
            font-size: 20px;
            font-weight: bold;
         }
      }
   }
   .pagination__page-link.current {
      font-weight: $fontWeight-bold;
      color: $color-secondary;
   }
   a.pagination__page-link {
      color: $color-primary;
   }
   
   .js-pagination {
      display:flex;
      align-items: center;
      justify-content: flex-end;
      padding:10px 0 0 0;
      border-top:1px solid #999999;
   
      a {
          padding:3px 5px;
          display:block;
          height:30px;
          width:22px;
          text-align:Center;
          position:relative;
          color:$color-base;
      }
   }
   .js-pagination-inner {
      display:flex;
      align-items: center;
   }
   .js-pagination__previous,
   .js-pagination__next {
      padding:3px 6px;
      display:block;
      height:30px;
      width:24px;
      text-align:Center;
      position:relative;
      color:$color-base;
      border:0;
      background:none;
      cursor:pointer;
   
      svg {
          width:20px;
          height:20px;
          left:50%;
          top:50%;
          position: absolute;;
          transform: translateY(-50%) translateX(-50%);
          fill:$color-secondary;
       }
      &.jp-disabled {
          display:none;
      }
   }
   .hawk-pagination__controls {
      .pagination__link--next {
         margin-left: auto;
      }
   }