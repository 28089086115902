.slider-product-list {
    &__nav-box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0 0;
        .slider-product-list__thumb:only-child {
            display: none;
        }
    }
    &__nav {
        width: 80%;
        position: relative;
        
    }
    &__image {
        background-size: contain;
        background-position: top center;
        background-repeat: no-repeat;
        .aspect-ratio {
            padding-top: 75%; /* 3:2 Aspect Ratio (divide 2 by 3 = 0.6666)  */
        }

        &--projects {
            .aspect-ratio {
                padding-top: 45%; /* 3:2 Aspect Ratio (divide 2 by 3 = 0.6666)  */
            }
        }
    }
    &__thumb {
        margin: auto 5px;
        border: 2px solid transparent;
        cursor: pointer;
        background-size: cover;
        background-position: center center;
        &.slick-current {
            border: 2px solid $color-secondary;
        }
        .aspect-ratio {
            padding-top: 75%; /* 3:2 Aspect Ratio (divide 2 by 3 = 0.6666)  */
        }
    }
    &__btn--prev, &__btn--next {
        margin: 0;
        position: relative !important;
        left: 0 !important;
        background: none;

        svg {
            fill: $color-secondary;
            width: 15px !important;
            height: inherit !important;
        }
    }
}

.gallery, .post .gallery {
    position: absolute;
    right: 0;
    top: 0;
    .zoom-image { 
        background-color: $color-secondary;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before, &:after {
            position: absolute;
            content: '';
            height: 12px;
            width: 3px;
            background-color: #fff;
        }
        &:before {
            transform: rotate(0deg);
        }
        &:after {
            transform: rotate(90deg);
        }

        &:hover, &:focus {
            opacity: .7;
        }
    }
}